import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
    SttTranslateHook,
} from '@stt-componentes/core';
import TabelaHistorico from './tabelaHistorico';
import axios from 'axios';
import { getHeaders } from '../../../request';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { batch, signal, useSignalEffect } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import carregando from '../../../signals/carregando';
import { historicoPaciente, parametrosHistoricoPaciente } from '../../../signals/apac';
import { PERFIL } from '../../../common/Constants';
import Legenda from './legenda';
import { buscarHistorico } from '../../../signals/util';
import { temPerfilRBAC } from '../../../security/acl';
import { usuario } from '../../../signals';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        marginTop: theme.spacing(2),
    },
    table: {
        overflow: 'auto',
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        height: theme.spacing(4),
        width: theme.spacing(13),
        '& span': {
            whiteSpace: 'pre-wrap',
        },
    },
    tableRowOriginal: {
        backgroundColor: '#ecf3fe !important',
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc',
    },
    CAMPOS: {
        SEQUENCIAL: 'sequencial',
        DATA_SOLICITACAO: 'data_solicitacao',
        NOME_PROCEDIMENTO: 'nome_procedimento',
        CODIGO_PROCEDIMENTO: 'codigo_procedimento',
        NOME_INSTITUICAO_SOLICITANTE: 'nome_instituicao_solicitante',
        CIDADE_SOLICITANTE: 'cidade_solicitante',
        NOME_PACIENTE: 'nome_paciente',
        CPF_PACIENTEE: 'cpf_paciente',
        CNS_PACIENTE: 'cns_paciente',
        DATA_AUTORIZACAO: 'data_autorizacao',
        NOME_FUNCIONARIO_AUTORIZADOR: 'nome_funcionario_autorizador',
        SITUACAO: 'situacao',
        DATA_SOLICITACAO_LAUDO: 'data_solicitacao_laudo',
        NUMERAL: 'numeral',
    },
};

const page = signal(null);
const count = signal(null);
const order = signal(ORDENACAO.DIRECAO.DESC);
const orderBy = signal(ORDENACAO.CAMPOS.DATA_RESPOSTA);

const Historico = ({ adicionarAba }) => {
    useSignals();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useSignals();

    useEffect(() => {
        batch(() => {
            page.value = global.gConfig.pagination.start;
            count.value = global.gConfig.pagination.count;
            buscarHistorico.value = true;
        });
    }, []);

    const handleClickSort = (campo) => {
        const isAsc = orderBy.value === campo && order.value === ORDENACAO.DIRECAO.ASC;
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        batch(() => {
            order.value = newOrder;
            orderBy.value = campo;
            buscarHistorico.value = true;
        });
    };

    const handleChangePage = (event, newPage) => {
        batch(() => {
            page.value = newPage;
            buscarHistorico.value = true;
        });
    };

    const handleChangeRowsPerPage = (event) => {
        batch(() => {
            count.value = event.target.value;
            page.value = global.gConfig.pagination.start;
            buscarHistorico.value = true;
        });
    };

    const buscarHistoricoApac = () => {
        carregando.value = {
            open: true,
            text: strings.carregando,
        };

        let params = { ...parametrosHistoricoPaciente.value };

        if (orderBy.value && order.value) {
            params.sort = orderBy.value;
            params.direction = order.value;
        }

        const offset = page.value * count.value;
        params.start = offset;
        params.count = count.value;

        const APAC_API_BASE_URL = global.gConfig.url_base_apac;

        axios
            .get(`${APAC_API_BASE_URL}/administrativo/historico`, { params, headers: getHeaders() })
            .then((response) => {
                const { totalRegistros, itens } = response.data.data;
                historicoPaciente.value = { totalRegistros, itens: formatarDados(itens) };
                parametrosHistoricoPaciente.value = params;
            })
            .catch((err) => {
                historicoPaciente.value = {};
            })
            .finally(() => {
                batch(() => {
                    carregando.value = {
                        open: false,
                        text: '',
                    };
                    buscarHistorico.value = false;
                });
            });
    };

    const formatarDados = (itens) => {
        for (const item of itens) {
            if (item.nome_instituicao_solicitante) {
                item.nome_instituicao_solicitante = item.nome_instituicao_solicitante.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
            }

            if (item.nome_procedimento) {
                item.nome_procedimento = item.nome_procedimento.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
            }

            if (item.nome_paciente) {
                item.nome_paciente = item.nome_paciente.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
            }

            if (item.nome_funcionario_autorizador) {
                item.nome_funcionario_autorizador = item.nome_funcionario_autorizador.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
            }

            if (item.processosContinuidade?.length) {
                item.processosContinuidade = formatarDados(item.processosContinuidade);
            }
        }
        return itens;
    };

    useSignalEffect(() => {
        if (buscarHistorico.value) {
            buscarHistoricoApac();
        }
    });

    return (
        <>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden mdDown>
                                <SttTableCell />
                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.SEQUENCIAL ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.SEQUENCIAL}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.SEQUENCIAL ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SEQUENCIAL)}
                                    >
                                        {strings.sequencial}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_SOLICITACAO)}
                                    >
                                        {strings.dataSolicitacao}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.NOME_INSTITUICAO_SOLICITANTE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NOME_INSTITUICAO_SOLICITANTE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NOME_INSTITUICAO_SOLICITANTE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME_INSTITUICAO_SOLICITANTE)}
                                    >
                                        {strings.instituicaoSolicitante}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.NOME_PROCEDIMENTO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NOME_PROCEDIMENTO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NOME_PROCEDIMENTO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME_PROCEDIMENTO)}
                                    >
                                        {strings.nomeProcedimento}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.CODIGO_PROCEDIMENTO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.CODIGO_PROCEDIMENTO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.CODIGO_PROCEDIMENTO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CODIGO_PROCEDIMENTO)}
                                    >
                                        {strings.codigoProcedimento}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell>{strings.nomePaciente}</SttTableCell>

                                <SttTableCell>{strings.cnsPaciente}</SttTableCell>

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.NOME_FUNCIONARIO_AUTORIZADOR ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NOME_FUNCIONARIO_AUTORIZADOR}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NOME_FUNCIONARIO_AUTORIZADOR ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME_FUNCIONARIO_AUTORIZADOR)}
                                    >
                                        {strings.nomeAutorizador}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                {temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR) ? (
                                    <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO_LAUDO ? order.value : false}>
                                        <SttTableSortLabel
                                            active={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO_LAUDO}
                                            direction={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO_LAUDO ? order.value : ORDENACAO.DIRECAO.ASC}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_SOLICITACAO_LAUDO)}
                                        >
                                            {strings.dataSolicitacaoLaudo}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                ) : (
                                    <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_AUTORIZACAO ? order.value : false}>
                                        <SttTableSortLabel
                                            active={orderBy.value === ORDENACAO.CAMPOS.DATA_AUTORIZACAO}
                                            direction={orderBy.value === ORDENACAO.CAMPOS.DATA_AUTORIZACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_AUTORIZACAO)}
                                        >
                                            {strings.dataAutorizacao}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                )}

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.SITUACAO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.SITUACAO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.SITUACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SITUACAO)}
                                    >
                                        {strings.situacao}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell align="center">{strings.visualizar}</SttTableCell>
                            </SttHidden>
                            <SttHidden lgUp>
                                <SttTableCell width="100%" colSpan="2">
                                    {strings.pesquisaAPAC}
                                </SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>

                    <SttTableBody>
                        {!carregando.value.open &&
                            (historicoPaciente.value.totalRegistros === 0 ? (
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={12} align="center">
                                        {strings.nenhumRegistroEncontrado}
                                    </SttTableCell>
                                </SttTableRow>
                            ) : (
                                historicoPaciente.value.itens?.map((row, index) => {
                                    return <TabelaHistorico row={row} key={index} processoOriginal={true} adicionarAba={adicionarAba} />;
                                })
                            ))}
                    </SttTableBody>
                    {
                        <>
                            <SttHidden mdDown>
                                <caption colSpan={13}>
                                    <Legenda />
                                </caption>
                            </SttHidden>
                            <SttHidden lgUp>
                                <caption width="96%">
                                    <Legenda />
                                </caption>
                            </SttHidden>
                        </>
                    }
                </SttTable>
            </TableContainer>

            {historicoPaciente.value.totalRegistros > 0 && (
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={historicoPaciente.value.totalRegistros}
                    rowsPerPage={count.value}
                    page={page.value}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            )}
        </>
    );
};

export default React.memo(Historico);
