import React, { useRef, memo, useContext, lazy, Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SttButton, SttGrid, SttTranslateHook, SttDatePicker, SttHeading, SttCircularProgress } from '@stt-componentes/core';
import { Formik, Field } from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../request';
import alerta from '../../signals/alerta';
import carregando from '../../signals/carregando';
import moment from 'moment';

const hoje = new Date();
hoje.setHours(0, 0, 0, 0);

const validationSchema = (strings) => {
    return yup.object().shape({
        dataValidade: yup.date().typeError(strings.dataInvalida).nullable().required(strings.campoObrigatorio),
        dataFinalValidade: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(yup.ref('dataValidade'), strings.dataFinalAnteriorDataInicial)
            .nullable()
            .required(strings.campoObrigatorio),
        instituicao: yup
            .object()
            .shape({
                id: yup.number(),
            })
            .required(strings.campoObrigatorio)
            .nullable(),
    });
};

const SttInstituicao = lazy(() => import('@stt-componentes/instituicao'));

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: 0,
    },
    button: {
        marginBottom: theme.spacing(1),
    },
}));

const Aprovar = ({ callback, callbackSucesso, id, outrosDados }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const schema = validationSchema(strings);
    const formRef = useRef();
    const { dataValidade, dataFinalValidade, instituicaoExecutora } = outrosDados.value;

    const initialValues = {
        dataValidade: dataValidade ? moment(dataValidade) : null,
        dataFinalValidade: dataFinalValidade ? moment(dataFinalValidade) : null,
        instituicao: instituicaoExecutora || null,
    };

    const closeAlerta = (resetForm) => {
        alerta.value = {
            ...alerta.value,
            open: false,
        };
        resetForm({ values: initialValues });
        callbackSucesso();
    };

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dados, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                carregando.value = {
                    open: true,
                    text: strings.carregando,
                };

                const formData = new FormData();
                formData.append('id', id);
                formData.append('dataValidade', moment(dados.dataValidade).toISOString());
                formData.append('dataFinalValidade', moment(dados.dataFinalValidade).toISOString());
                formData.append('idInstituicaoExecutora', dados.instituicao.id);

                const APAC_API_BASE_URL = global.gConfig.url_base_apac;

                axios
                    .post(`${APAC_API_BASE_URL}/operacao/aprovar`, formData, { headers: { ...getHeaders() } })
                    .then((response) => {
                        const { data } = response;
                        if (response.status === HttpStatus.OK) {
                            alerta.value = {
                                open: true,
                                title: strings.sucesso,
                                type: 'success',
                                message: data.message ? data.message : strings.sucessoOperacao,
                                options: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            closeAlerta(resetForm);
                                        },
                                    },
                                ],
                                onClose: () => {
                                    closeAlerta(resetForm);
                                },
                            };
                        }
                    })
                    .catch((err) => {
                        const { response } = err;
                        let mensagem = strings.mensagemErroGeral;

                        if (response) {
                            if (response.status === HttpStatus.BAD_REQUEST) {
                                const { data } = response;
                                let arrMensagem = [];
                                data.errors.forEach((error) => {
                                    arrMensagem.push(`- ${error.message}`);
                                });
                                if (arrMensagem) {
                                    mensagem = arrMensagem.join('\n');
                                }
                            }
                        }
                        alerta.value = {
                            open: true,
                            title: strings.erro,
                            type: 'error',
                            message: mensagem,
                            options: [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false,
                                        };
                                    },
                                },
                            ],
                            onClose: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false,
                                };
                            },
                        };
                    })
                    .finally(() => {
                        setSubmitting(false);
                        carregando.value = {
                            open: false,
                            text: '',
                        };
                    });
            }}
        >
            {({ isSubmitting, handleSubmit, resetForm, values, ...formikEvents }) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <SttGrid container spacing={3} className={classes.container}>
                            <SttGrid item xs={12} sm={6}>
                                <Field name={'dataValidade'}>
                                    {({ field: { name, value }, form: { setFieldValue, setFieldTouched }, meta }) => (
                                        <SttDatePicker
                                            label={strings.dataValidade}
                                            inputprops={{
                                                name: name,
                                            }}
                                            error={meta.touched && meta.error ? true : false}
                                            value={value}
                                            onBlur={() => {
                                                setFieldTouched('dataValidade', true);
                                            }}
                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                            onChange={(date) => {
                                                setFieldValue('dataValidade', date, true);
                                            }}
                                            onClose={() => setFieldTouched('dataValidade', true)}
                                        />
                                    )}
                                </Field>
                            </SttGrid>

                            <SttGrid item xs={12} sm={6}>
                                <Field name={'dataFinalValidade'}>
                                    {({ field: { name, value }, form: { setFieldValue, setFieldTouched }, meta }) => (
                                        <SttDatePicker
                                            label={strings.dataFinalValidade}
                                            inputprops={{
                                                name: name,
                                            }}
                                            error={meta.touched && meta.error ? true : false}
                                            value={value}
                                            onBlur={() => {
                                                setFieldTouched('dataFinalValidade', true);
                                            }}
                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                            onChange={(date) => {
                                                setFieldValue('dataFinalValidade', date, true);
                                            }}
                                            onClose={() => setFieldTouched('dataFinalValidade', true)}
                                        />
                                    )}
                                </Field>
                            </SttGrid>

                            <SttGrid item xs={12} sm={6}>
                                <SttHeading required variant="h4" color="primary">
                                    {strings.instituicaoExecutora}
                                </SttHeading>
                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                    <SttInstituicao
                                        fieldName={'instituicao'}
                                        headers={getHeaders()}
                                        strings={strings}
                                        obrigatorio={true}
                                        formExterno={{
                                            instituicao: values.instituicao,
                                            ...formikEvents,
                                        }}
                                    />
                                </Suspense>
                            </SttGrid>
                        </SttGrid>
                        <SttGrid container spacing={2}>
                            <SttGrid item xs={12}>
                                <SttButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    nomarginleft="true"
                                    className={classes.button}
                                    disabled={isSubmitting}
                                >
                                    {strings.confirmar}
                                </SttButton>
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    disabled={isSubmitting}
                                    className={classes.button}
                                    onClick={() => {
                                        resetForm({ values: initialValues });
                                    }}
                                >
                                    {strings.limpar}
                                </SttButton>
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    disabled={isSubmitting}
                                    className={classes.button}
                                    onClick={() => {
                                        resetForm({ values: initialValues });
                                        callback();
                                    }}
                                >
                                    {strings.fechar}
                                </SttButton>
                            </SttGrid>
                        </SttGrid>
                    </form>
                );
            }}
        </Formik>
    );
};

export default Aprovar;
