import axios from 'axios';
import { getHeaders } from '../../request';
import carregando from '../../signals/carregando';
import { procedimentos } from '../../signals/util';
import alerta from '../../signals/alerta';
import { OPCAO_OUTROS_PROCEDIMENTOS, PERFIL, situacao, TIPO_ANEXO_SIGLA, TIPO_SOLICITACAO } from '../../common/Constants';
import { NotificationManager } from 'react-notifications';
import { historicoPaciente, parametrosHistoricoPaciente } from '../../signals/apac';
import { usuario } from '../../signals';
import { temPerfilRBAC } from '../../security/acl';
import moment from 'moment';
import UpdateIcon from '@material-ui/icons/Update';
import { Tooltip, IconButton } from '@material-ui/core';

export const baixarArquivoApac = (url, mensagem, anexo) => {
    carregando.value = {
        open: true,
        text: mensagem,
    };
    if (anexo.download) {
        axios
            .get(url, { responseType: 'blob', headers: getHeaders() })
            .then((response) => {
                const blob = response.data;
                const file = new File([blob], anexo.nome, { lastModified: new Date().getTime(), type: blob.type });
                const fileURL = URL.createObjectURL(file);

                const link = document.createElement('a');
                link.href = fileURL;
                link.download = anexo.nome;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                URL.revokeObjectURL(fileURL);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                carregando.value = {
                    open: false,
                    text: '',
                };
            });
    } else {
        axios
            .get(url, { responseType: 'blob', headers: getHeaders() })
            .then((response) => {
                var fileURL = URL.createObjectURL(response.data);
                window.open(fileURL);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                carregando.value = {
                    open: false,
                    text: '',
                };
            });
    }
};

export const procedimentosApac = (strings) => {
    if (procedimentos.value?.length) {
        return;
    }
    const APAC_API_BASE_URL = global.gConfig.url_base_apac;
    axios
        .get(`${APAC_API_BASE_URL}/administrativo/procedimento-sigtap`, { headers: getHeaders() })
        .then((response) => {
            if (response.data) {
                const dados = response.data;
                procedimentos.value = [...dados, OPCAO_OUTROS_PROCEDIMENTOS];
            }
        })
        .catch((err) => {
            alerta.value = {
                ...alerta.value,
                open: true,
                title: strings.erro,
                type: 'error',
                message: strings.erroPesquisaProcedimentoSigtap,
                options: [
                    {
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false,
                            };
                        },
                    },
                ],
                onClose: () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false,
                    };
                },
            };
        });
};

export const tiposAnexoApac = async () => {
    const APAC_API_BASE_URL = global.gConfig.url_base_apac;

    return axios
        .get(`${APAC_API_BASE_URL}/administrativo/tipo-anexo`, { headers: getHeaders() })
        .then((response) => {
            if (response.data) {
                let itens = response.data;
                return itens;
            }
        })
        .catch((err) => {
            console.log(err);
            return [];
        });
};

export const verificarApacContinuidade = (strings, values, setFieldValue, setPossibilidadeEscolherApacContinuidade) => {
    if (values.paciente?.id && values.procedimento.novoProcedimento && values.procedimento.instituicaoSolicitante) {
        const params = {
            idPaciente: values.paciente.id,
            codigoProcedencia: values.procedimento.novoProcedimento.codigo,
            anoMesCompetencia: values.procedimento.novoProcedimento.ano_mes_competencia,
            idInstituicaoSolicitante: values.procedimento.instituicaoSolicitante.id,
            idProcesso: values.id,
        };

        const APAC_API_BASE_URL = global.gConfig.url_base_apac;
        let mensagem;
        axios
            .get(`${APAC_API_BASE_URL}/solicitacao/verificar-apac-continuidade`, { params: params, headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    if (response.data.data.length) {
                        setPossibilidadeEscolherApacContinuidade(true);
                        setFieldValue('procedimento.idApacOriginal', response.data.data[0].id);
                        mensagem = strings.informeApacContinuidade;
                    } else {
                        setFieldValue('procedimento.tipoSolicitacao', TIPO_SOLICITACAO.PADRAO);
                        setFieldValue('procedimento.idApacOriginal', null);
                        // modificarAnexosTipoSolicitacao(TIPO_SOLICITACAO.PADRAO, setFieldValue, values.anexos);
                        setPossibilidadeEscolherApacContinuidade(false);
                        mensagem = strings.informeApacPadrao;
                    }
                }
            })
            .catch((err) => {
                console.log('Erro ao verificar a APAC continuidade', err);
            })
            .finally(() => {
                if (mensagem) {
                    NotificationManager.success(mensagem);
                }
            });
    } else {
        setFieldValue('procedimento.tipoSolicitacao', TIPO_SOLICITACAO.PADRAO);
        setPossibilidadeEscolherApacContinuidade(false);
        // modificarAnexosTipoSolicitacao(TIPO_SOLICITACAO.PADRAO, setFieldValue, values.anexos);
    }
};

export const modificarAnexosTipoSolicitacao = (tipoSolicitacao, setFieldValue, anexos) => {
    let novosAnexos = [];
    tiposAnexoApac().then((itens) => {
        if (tipoSolicitacao === TIPO_SOLICITACAO.PADRAO) {
            novosAnexos = [
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter((item) => item.sigla === TIPO_ANEXO_SIGLA.HISTORICO_SGPE)[0],
                    observacao: '',
                },
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter((item) => item.sigla === TIPO_ANEXO_SIGLA.LAUDO_MEDICO)[0],
                    observacao: '',
                },
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter((item) => item.sigla === TIPO_ANEXO_SIGLA.EXAMES_OBRIGATORIOS)[0],
                    observacao: '',
                },
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter((item) => item.sigla === TIPO_ANEXO_SIGLA.SISREG)[0],
                    observacao: '',
                },
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter((item) => item.sigla === TIPO_ANEXO_SIGLA.DOCUMENTO_PESSOAL)[0],
                    observacao: '',
                },
            ];
        }

        if (tipoSolicitacao === TIPO_SOLICITACAO.CONTINUIDADE) {
            novosAnexos = [
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter((item) => item.sigla === TIPO_ANEXO_SIGLA.LAUDO_MEDICO)[0],
                    observacao: '',
                },
                {
                    id: null,
                    anexo: null,
                    tipoAnexo: itens.filter((item) => item.sigla === TIPO_ANEXO_SIGLA.EXAMES_OBRIGATORIOS)[0],
                    observacao: '',
                },
            ];
        }

        if (anexos) {
            anexos.forEach((anexo) => {
                const { tipoAnexo: tipo } = anexo;
                let tipoAnexo = null;

                if (tipo) {
                    tipoAnexo = tipo.sigla ? itens.find((t) => t.sigla === tipo.sigla) : itens.find((t) => t.id === tipo);
                } else {
                    tipoAnexo = itens.find((item) => item.sigla === TIPO_ANEXO_SIGLA.HISTORICO_SGPE);
                }

                anexo.tipoAnexo = tipoAnexo;

                if (anexo.anexo instanceof File) {
                    const novoAnexoExistente = novosAnexos.find((novo) => novo.tipoAnexo.id === tipoAnexo.id);

                    if (novoAnexoExistente) {
                        if (!novoAnexoExistente.anexo) {
                            Object.assign(novoAnexoExistente, {
                                id: anexo.id,
                                anexo: anexo.anexo,
                                observacao: anexo.observacao,
                            });
                        } else {
                            novosAnexos.push(anexo);
                        }
                    } else {
                        novosAnexos.push(anexo);
                    }
                }
            });
        }

        setFieldValue('anexos', novosAnexos);
    });
};

export const verificarHistoricoProcesso = (values) => {
    if (values.paciente?.id && values.procedimento.novoProcedimento && values.procedimento.instituicaoSolicitante) {
        const params = {
            idPaciente: values.paciente.id,
            count: global.gConfig.pagination.count,
            sort: global.gConfig.pagination.sort,
        };

        const APAC_API_BASE_URL = global.gConfig.url_base_apac;
        axios
            .get(`${APAC_API_BASE_URL}/administrativo/historico`, { params: params, headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    historicoPaciente.value = response.data.data;
                    parametrosHistoricoPaciente.value = params;
                }
            })
            .catch((err) => {
                console.log('Erro ao verificar a APAC continuidade', err);
                historicoPaciente.value = {};
                parametrosHistoricoPaciente = {};
            });
    }
};

export const tratarDadoNuloOuVazio = (dado) => {
    if (dado && dado !== '' && dado !== undefined) {
        return dado.trim();
    }

    return null;
};

export const verificarArraySubsetProcessosEncaminhamento = (arrayA, arrayB) => {
    return arrayA.every((a) => arrayB.some((b) => a.id === b.id)) && arrayA.length > 0;
};

export const verificarAvatarSituacao = (processo, strings) => {
    if (processo.numero_autorizacao_apac) {
        return <img src="/static/impressora.svg" />;
    }

    if (
        (temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_ADMINISTRADOR) || temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR)) &&
        processo.situacao === situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR
    ) {
        const dataSolicitacao = moment(processo.data_solicitacao);
        const dataAtual = moment();

        const diff = moment.duration(moment(dataAtual).diff(moment(dataSolicitacao)));

        const diferencaHoras = Math.floor(diff.asHours());

        let cor = '';
        let legenda = strings.tooltipEncaminhamento48Horas;
        if (diferencaHoras >= 12 && diferencaHoras < 24) {
            cor = '#0A6FB8';
            legenda = strings.tooltipEncaminhamento36Horas;
        } else if (diferencaHoras >= 24 && diferencaHoras < 48) {
            cor = '#E0842F';
            legenda = strings.tooltipEncaminhamento24Horas;
        } else if (diferencaHoras >= 48) {
            cor = '#E0442F';
            legenda = strings.tooltipEncaminhamentoAtrasado;

            return (
                <Tooltip title={legenda}>
                    <IconButton>
                        <img src="/static/encaminhamento_atrasado.svg" />
                    </IconButton>
                </Tooltip>
            );
        }
        return (
            <Tooltip title={legenda}>
                <IconButton>
                    <UpdateIcon style={{ color: cor }} />
                </IconButton>
            </Tooltip>
        );
    }
};
