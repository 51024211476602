import React, { useRef, useContext, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import alerta from '../../../signals/alerta';
import validationSchema from './validationSchema';
import carregando from '../../../signals/carregando';
import { filtros, pesquisarProcessos, resetFiltros } from '../../../signals/apac';
import { makeStyles } from '@material-ui/core/styles';
import { useSignalEffect, useSignals, useSignal } from '@preact/signals-react/runtime';
import { batch } from '@preact/signals-react';
import { estadoInicial } from './estadoInicial';
import { getHeaders } from '../../../request';
import {
    CNES_INSTITUICAO_SOLICITANTE,
    DATA_INICIAL_SOLICITACAO,
    DATA_FINAL_SOLICITACAO,
    NOME_PACIENTE,
    DESCRICAO_PROCEDIMENTO,
    SITUACAO,
    NUMERO_APAC,
    NOME_INSTITUICAO_SOLICITANTE,
    CODIGO_PROCEDIMENTO,
    NOME_FUNCIONARIO_AUTORIZADOR,
    DATA_INICIAL_AUTORIZACAO,
    DATA_FINAL_AUTORIZACAO,
    CPF_PACIENTE,
    CNS_PACIENTE,
    DATA_INICIAL_SOLICITACAO_LAUDO,
    DATA_FINAL_SOLICITACAO_LAUDO,
} from './fieldNames';
import {
    SttGrid,
    SttInput,
    SttButton,
    SttTranslateHook,
    SttDatePicker,
    SttAutocomplete,
    SttMaskedInput,
    SttNumberInput,
    SttCard,
    SttCardHeader,
    SttCardContent,
} from '@stt-componentes/core';
import { Formik, Field, FastField } from 'formik';
import { procedimentosApac } from '../../util';
import { formatarSituacaoNumeroApac, formatarSituacaoProcessoFiltro, PERFIL } from '../../../common/Constants';
import { temPerfilRBAC } from '../../../security/acl';
import { usuario } from '../../../signals';

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const Form = (props) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { callbackLimpar, page, start, count, orderBy, order, callbackBusca } = props;
    const APAC_API_BASE_URL = global.gConfig.url_base_apac;
    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();

    useEffect(() => {
        procedimentosApac(strings);
    }, []);

    useSignalEffect(() => {
        if (pesquisarProcessos.value && formRef.current) {
            formRef.current.handleSubmit();
        }
    });

    const estruturarFiltros = (dados) => {
        const filtrosPesquisa = {
            start: start.value,
            count: count.value,
            direction: order.value,
            sort: orderBy.value,
        };

        if (dados.data_inicial_solicitacao) {
            filtrosPesquisa.dataInicialSolicitacao = moment(dados.data_inicial_solicitacao).format('YYYY-MM-DD');
        }

        if (dados.data_final_solicitacao) {
            filtrosPesquisa.dataFinalSolicitacao = moment(dados.data_final_solicitacao).format('YYYY-MM-DD');
        }

        if (dados.data_inicial_autorizacao) {
            filtrosPesquisa.dataInicialAutorizacao = moment(dados.data_inicial_autorizacao).format('YYYY-MM-DD');
        }

        if (dados.data_final_autorizacao) {
            filtrosPesquisa.dataFinalAutorizacao = moment(dados.data_final_autorizacao).format('YYYY-MM-DD');
        }

        if (dados.data_inicial_solicitacao_laudo) {
            filtrosPesquisa.dataInicialSolicitacaoLaudo = moment(dados.data_inicial_solicitacao_laudo).format('YYYY-MM-DD');
        }

        if (dados.data_final_solicitacao_laudo) {
            filtrosPesquisa.dataFinalSolicitacaoLaudo = moment(dados.data_final_solicitacao_laudo).format('YYYY-MM-DD');
        }

        if (dados.nome_paciente) {
            filtrosPesquisa.nomePaciente = dados.nome_paciente.trim();
        }

        if (dados.cns_paciente) {
            filtrosPesquisa.cnsPaciente = dados.cns_paciente.replace(/\s+/g, '');
        }

        if (dados.cpf_paciente) {
            filtrosPesquisa.cpfPaciente = ('00000000000' + dados.cpf_paciente.replace(/[\. ,:-]+/g, '')).slice(-11);
        }

        if (dados.nome_instituicao_solicitante) {
            filtrosPesquisa.nomeSolicitante = dados.nome_instituicao_solicitante;
        }

        if (dados.cnes_instituicao_solicitante) {
            filtrosPesquisa.cnesSolicitante = dados.cnes_instituicao_solicitante;
        }

        if (dados.nome_funcionario_autorizador) {
            filtrosPesquisa.nomeAutorizador = dados.nome_funcionario_autorizador;
        }

        if (dados.descricao_procedimento) {
            filtrosPesquisa.nomeProcedimento = dados.descricao_procedimento;
        }

        if (dados.codigo_procedimento) {
            filtrosPesquisa.codigoProcedimento = dados.codigo_procedimento;
        }

        if (dados.situacao) {
            filtrosPesquisa.situacao = dados.situacao.id;
        }

        if (dados.numero_apac && dados.numero_apac !== 'T') {
            filtrosPesquisa.situacaoNumeroApac = dados.numero_apac.id;
        }

        return filtrosPesquisa;
    };
    return (
        <Formik
            innerRef={formRef}
            initialValues={filtros.value}
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={(data, { setSubmitting }) => {
                // Salva os parâmetros da pesquisa para manter estado do
                // formulário durante a navegação entre páginas
                filtros.value = data;
                let params = estruturarFiltros(data);
                carregando.value = {
                    open: true,
                    text: strings.carregandoProcessos,
                };
                setSubmitting(true);
                axios
                    .get(`${APAC_API_BASE_URL}/solicitacao`, { params: params, headers: getHeaders() })
                    .then((response) => {
                        const dados = response.data.data;
                        callbackBusca(dados);
                    })
                    .catch((err) => {
                        callbackBusca({
                            totalRegistros: 0,
                            itens: [],
                        });
                        alerta.value = {
                            ...alerta.value,
                            open: true,
                            title: strings.erro,
                            type: 'error',
                            message: strings.mensagemErroGeral,
                            options: [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false,
                                        };
                                    },
                                },
                            ],
                            onClose: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false,
                                };
                            },
                        };
                    })
                    .finally(() => {
                        carregando.value = {
                            open: false,
                            text: '',
                        };
                        setSubmitting(false);
                        pesquisarProcessos.value = false;
                    });
            }}
        >
            {({ isSubmitting, handleSubmit, resetForm }) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <SttGrid container spacing={1}>
                            <SttGrid item xs={12} sm={12} md={6} lg={4}>
                                <SttCard variant="outlined">
                                    <SttCardHeader subheader={strings.solicitacao} />
                                    <SttCardContent>
                                        <SttGrid container spacing={1}>
                                            <SttGrid item xs={12} sm={6}>
                                                <Field name={DATA_INICIAL_SOLICITACAO}>
                                                    {({ field: { name, value }, form: { setFieldValue, setFieldTouched }, meta }) => {
                                                        return (
                                                            <SttDatePicker
                                                                label={strings.dataInicialSolicitacao}
                                                                inputprops={{
                                                                    name: name,
                                                                }}
                                                                disableFuture
                                                                maxDate={new Date()}
                                                                error={meta.touched && meta.error ? true : false}
                                                                value={value}
                                                                onBlur={() => {
                                                                    setFieldTouched(DATA_INICIAL_SOLICITACAO, true);
                                                                }}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                onChange={(date) => {
                                                                    setFieldValue(DATA_INICIAL_SOLICITACAO, date, true);
                                                                }}
                                                                onClose={() => setFieldTouched(DATA_INICIAL_SOLICITACAO, true)}
                                                            />
                                                        );
                                                    }}
                                                </Field>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={6}>
                                                <Field name={DATA_FINAL_SOLICITACAO}>
                                                    {({ field: { name, value }, form: { setFieldValue, setFieldTouched }, meta }) => {
                                                        return (
                                                            <SttDatePicker
                                                                label={strings.dataFinalSolicitacao}
                                                                inputprops={{
                                                                    name: name,
                                                                }}
                                                                disableFuture
                                                                maxDate={new Date()}
                                                                error={meta.touched && meta.error ? true : false}
                                                                value={value}
                                                                onBlur={() => {
                                                                    setFieldTouched(DATA_FINAL_SOLICITACAO, true);
                                                                }}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                onChange={(date) => setFieldValue(DATA_FINAL_SOLICITACAO, date, true)}
                                                                onClose={() => setFieldTouched(DATA_FINAL_SOLICITACAO, true)}
                                                            />
                                                        );
                                                    }}
                                                </Field>
                                            </SttGrid>
                                        </SttGrid>
                                    </SttCardContent>
                                </SttCard>
                            </SttGrid>

                            <SttGrid item xs={12} sm={12} md={6} lg={4}>
                                <SttCard variant="outlined">
                                    <SttCardHeader subheader={strings.instituicaoSolicitante} />
                                    <SttCardContent>
                                        <SttGrid container spacing={1}>
                                            <SttGrid item xs={12} sm={6}>
                                                <FastField name={NOME_INSTITUICAO_SOLICITANTE}>
                                                    {({ field, meta }) => (
                                                        <SttInput
                                                            {...field}
                                                            label={strings.nome}
                                                            inputProps={{ style: { textTransform: 'uppercase' } }}
                                                            value={field.value ? field.value : ''}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>

                                            <SttGrid item xs={12} sm={6}>
                                                <Field name={CNES_INSTITUICAO_SOLICITANTE}>
                                                    {({ field }) => (
                                                        <SttNumberInput
                                                            {...field}
                                                            label={strings.cnes}
                                                            value={field.value ? field.value : ''}
                                                            inputProps={{
                                                                maxLength: 10,
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </SttGrid>
                                        </SttGrid>
                                    </SttCardContent>
                                </SttCard>
                            </SttGrid>

                            <SttGrid item xs={12} sm={12} md={6} lg={4}>
                                <SttCard variant="outlined">
                                    <SttCardHeader subheader={strings.procedimento} />
                                    <SttCardContent>
                                        <SttGrid container spacing={1}>
                                            <SttGrid item xs={12} sm={6}>
                                                <Field name={DESCRICAO_PROCEDIMENTO}>
                                                    {({ field, meta }) => (
                                                        <SttInput
                                                            {...field}
                                                            label={strings.descricaoProcedimento}
                                                            inputProps={{ style: { textTransform: 'uppercase' } }}
                                                            value={field.value ? field.value : ''}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </Field>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={6}>
                                                <Field name={CODIGO_PROCEDIMENTO}>
                                                    {({ field, meta }) => (
                                                        <SttNumberInput
                                                            {...field}
                                                            label={strings.codigoProcedimento}
                                                            inputProps={{ style: { textTransform: 'uppercase' } }}
                                                            value={field.value ? field.value : ''}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </Field>
                                            </SttGrid>
                                        </SttGrid>
                                    </SttCardContent>
                                </SttCard>
                            </SttGrid>
                            <SttGrid item xs={12} sm={12} md={6}>
                                <SttCard variant="outlined">
                                    <SttCardHeader subheader={strings.paciente} />
                                    <SttCardContent>
                                        <SttGrid container spacing={1}>
                                            <SttGrid item xs={12} sm={6} md={4}>
                                                <FastField name={NOME_PACIENTE}>
                                                    {({ field, meta }) => (
                                                        <SttInput
                                                            {...field}
                                                            label={strings.nome}
                                                            inputProps={{ style: { textTransform: 'uppercase' } }}
                                                            value={field.value ? field.value : ''}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>

                                            <SttGrid item xs={12} sm={6} md={4}>
                                                <FastField name={CPF_PACIENTE}>
                                                    {({ field, meta }) => (
                                                        <SttMaskedInput
                                                            {...field}
                                                            mask="cpf"
                                                            label={strings.cpf}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={6} md={4}>
                                                <FastField name={CNS_PACIENTE}>
                                                    {({ field, meta }) => (
                                                        <SttMaskedInput
                                                            {...field}
                                                            mask="cns"
                                                            label={strings.cns}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>
                                        </SttGrid>
                                    </SttCardContent>
                                </SttCard>
                            </SttGrid>

                            <SttGrid item xs={12} sm={12} md={8} lg={6}>
                                <SttCard variant="outlined">
                                    <SttCardHeader subheader={strings.autorizacao} />
                                    <SttCardContent>
                                        <SttGrid container spacing={1}>
                                            <SttGrid item xs={12} sm={6} md={4}>
                                                <FastField name={NOME_FUNCIONARIO_AUTORIZADOR}>
                                                    {({ field, meta }) => (
                                                        <SttInput
                                                            {...field}
                                                            label={strings.medicoAutorizador}
                                                            inputProps={{ style: { textTransform: 'uppercase' } }}
                                                            value={field.value ? field.value : ''}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={6} md={4}>
                                                <Field name={DATA_INICIAL_AUTORIZACAO}>
                                                    {({ field: { name, value }, form: { setFieldValue, setFieldTouched }, meta }) => {
                                                        return (
                                                            <SttDatePicker
                                                                label={strings.dataInicialAutorizacao}
                                                                inputprops={{
                                                                    name: name,
                                                                }}
                                                                disableFuture
                                                                maxDate={new Date()}
                                                                error={meta.touched && meta.error ? true : false}
                                                                value={value}
                                                                onBlur={() => {
                                                                    setFieldTouched(DATA_INICIAL_AUTORIZACAO, true);
                                                                }}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                onChange={(date) => {
                                                                    setFieldValue(DATA_INICIAL_AUTORIZACAO, date, true);
                                                                }}
                                                                onClose={() => setFieldTouched(DATA_INICIAL_AUTORIZACAO, true)}
                                                            />
                                                        );
                                                    }}
                                                </Field>
                                            </SttGrid>
                                            <SttGrid item xs={12} sm={6} md={4}>
                                                <Field name={DATA_FINAL_AUTORIZACAO}>
                                                    {({ field: { name, value }, form: { setFieldValue, setFieldTouched }, meta }) => {
                                                        return (
                                                            <SttDatePicker
                                                                label={strings.dataFinalAutorizacao}
                                                                inputprops={{
                                                                    name: name,
                                                                }}
                                                                disableFuture
                                                                maxDate={new Date()}
                                                                error={meta.touched && meta.error ? true : false}
                                                                value={value}
                                                                onBlur={() => {
                                                                    setFieldTouched(DATA_FINAL_AUTORIZACAO, true);
                                                                }}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                onChange={(date) => setFieldValue(DATA_FINAL_AUTORIZACAO, date, true)}
                                                                onClose={() => setFieldTouched(DATA_FINAL_AUTORIZACAO, true)}
                                                            />
                                                        );
                                                    }}
                                                </Field>
                                            </SttGrid>
                                        </SttGrid>
                                    </SttCardContent>
                                </SttCard>
                            </SttGrid>

                            {(temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR)) && (
                                <SttGrid item xs={12} sm={12} md={8} lg={6}>
                                    <SttCard variant="outlined">
                                        <SttCardHeader subheader={strings.solicitacaoLaudo} />
                                        <SttCardContent>
                                            <SttGrid container spacing={1}>
                                                <SttGrid item xs={12} sm={6}>
                                                    <Field name={DATA_INICIAL_SOLICITACAO_LAUDO}>
                                                        {({ field: { name, value }, form: { setFieldValue, setFieldTouched }, meta }) => {
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataInicial}
                                                                    inputprops={{
                                                                        name: name,
                                                                    }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_INICIAL_SOLICITACAO_LAUDO, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={(date) => {
                                                                        setFieldValue(DATA_INICIAL_SOLICITACAO_LAUDO, date, true);
                                                                    }}
                                                                    onClose={() => setFieldTouched(DATA_INICIAL_SOLICITACAO_LAUDO, true)}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6}>
                                                    <Field name={DATA_FINAL_SOLICITACAO_LAUDO}>
                                                        {({ field: { name, value }, form: { setFieldValue, setFieldTouched }, meta }) => {
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataFinal}
                                                                    inputprops={{
                                                                        name: name,
                                                                    }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_FINAL_SOLICITACAO_LAUDO, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={(date) => setFieldValue(DATA_FINAL_SOLICITACAO_LAUDO, date, true)}
                                                                    onClose={() => setFieldTouched(DATA_FINAL_SOLICITACAO_LAUDO, true)}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </SttGrid>
                                            </SttGrid>
                                        </SttCardContent>
                                    </SttCard>
                                </SttGrid>
                            )}

                            <SttGrid item xs={12} sm={6} md={4}>
                                <SttCard variant="outlined">
                                    <SttCardHeader subheader={strings.situacao} />
                                    <SttCardContent>
                                        <SttGrid container spacing={1}>
                                            <SttGrid item xs={6}>
                                                <Field name={SITUACAO}>
                                                    {({ field: { name, value, onBlur }, form: { setFieldValue } }) => (
                                                        <SttAutocomplete
                                                            inputprops={{
                                                                name: name,
                                                                label: strings.situacao,
                                                            }}
                                                            getOptionLabel={(option) => (option && option.descricao) || ''}
                                                            getOptionSelected={(option, valueOptionSelected) =>
                                                                option && valueOptionSelected && option.id === valueOptionSelected.id
                                                            }
                                                            options={formatarSituacaoProcessoFiltro()}
                                                            value={value || null}
                                                            onBlur={onBlur}
                                                            onChange={(e, item) => {
                                                                setFieldValue(SITUACAO, item || null);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </SttGrid>
                                            <SttGrid item xs={6}>
                                                <Field name={NUMERO_APAC}>
                                                    {({ field: { name, value, onBlur }, form: { setFieldValue } }) => (
                                                        <SttAutocomplete
                                                            inputprops={{
                                                                name: name,
                                                                label: strings.numeroApac,
                                                            }}
                                                            getOptionLabel={(option) => (option && option.descricao) || ''}
                                                            getOptionSelected={(option, valueOptionSelected) =>
                                                                option && valueOptionSelected && option.id === valueOptionSelected.id
                                                            }
                                                            options={formatarSituacaoNumeroApac()}
                                                            value={value || null}
                                                            onBlur={onBlur}
                                                            onChange={(e, item) => {
                                                                setFieldValue(NUMERO_APAC, item || null);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </SttGrid>
                                        </SttGrid>
                                    </SttCardContent>
                                </SttCard>
                            </SttGrid>
                        </SttGrid>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12} className={classes.buttonWrapper}>
                                <SttButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    nomarginleft="true"
                                    onClick={() => {
                                        callbackLimpar();
                                    }}
                                >
                                    {strings.pesquisar}
                                </SttButton>
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        callbackLimpar();
                                        resetFiltros();
                                        resetForm({
                                            values: estadoInicial(global.gConfig),
                                        });
                                        batch(() => {
                                            page.value = global.gConfig.pagination.start;
                                            count.value = global.gConfig.pagination.count;
                                            pesquisarProcessos.value = true;
                                        });
                                    }}
                                >
                                    {strings.limpar}
                                </SttButton>
                            </SttGrid>
                        </SttGrid>
                    </form>
                );
            }}
        </Formik>
    );
};

export default Form;
