import { OPCAO_OUTROS_PROCEDIMENTOS, PERFIL, PROCEDENCIA_PEDIDO, TIPO_ANEXO_SIGLA, TIPO_SOLICITACAO, situacao } from './Constants';
import { temPerfilRBAC } from '../security/acl';
import { usuario } from '../signals';
import moment from 'moment';

const iniciarValoresSolicitacao = (data) => {
    const dadosApac = {};

    dadosApac.id = data.id;

    dadosApac.situacao = data.situacao || situacao.RASCUNHO;

    const { paciente } = data;
    dadosApac.paciente = {
        id: paciente?.id || '',
        cpf: paciente?.cpf ? ('00000000000' + paciente?.cpf.replace(/[\. ,:-]+/g, '')).slice(-11) : '', // eslint-disable-line
        cns: paciente?.cartao_sus || '',
        nome: paciente?.nome || '',
        nomeMae: paciente?.nome_mae || '',
        fatorRh: paciente?.fator_rh,
        grupoSanguineo: paciente?.grupo_sanguineo,
        genero: paciente?.sexo || null,
        dataNascimento: paciente?.data_nascimento || null,
        id_endereco_paciente: data.id_endereco_paciente,
        municipio: paciente?.endereco?.id_cidade || null,
        uf: paciente?.endereco?.id_estado || null,
        numero: paciente?.endereco?.numero || '',
        pais: paciente?.endereco?.id_pais || null,
        peso: data.peso || '',
        altura: data.altura || '',
        cep: paciente?.endereco?.cep || '',
        logradouro: paciente?.endereco?.logradouro || '',
        bairro: paciente?.endereco?.bairro || '',
        tipoContato1: 1,
        contato1: '',
        tipoContato2: 2,
        contato2: '',
        tipoContatoEmail: 4,
        email: paciente?.email || '',
    };

    if (paciente?.contatos?.length) {
        const contatoTipo1 = paciente.contatos.filter((contato) => contato.id_tipo_contato === 1);
        if (contatoTipo1.length) {
            dadosApac.paciente.contato1 = contatoTipo1[0].contato;
        }

        const contatoTipo2 = paciente.contatos.filter((contato) => contato.id_tipo_contato === 2);
        if (contatoTipo2.length) {
            dadosApac.paciente.contato2 = contatoTipo2[0].contato;
        }

        const contatoTipoEmail = paciente.contatos.filter((contato) => contato.id_tipo_contato === 4);
        if (contatoTipoEmail.length) {
            dadosApac.paciente.email = contatoTipoEmail[0].contato;
        }
    }

    dadosApac.procedimento = {
        complementoProcedencia: data.complemento_procedencia || '',
        procedencia: data.procedencia_processo || PROCEDENCIA_PEDIDO.UNACON,
        quantidade: data.procedimento?.quantidade || 1,
        instituicaoSolicitante: data.instituicao_solicitante || null,
        instituicaoAutorizadora: data.instituicao_autorizadora || null,
        instituicaoExecutora: data.instituicao_executora || null,
        tipoSolicitacao: data.tipo || TIPO_SOLICITACAO.PADRAO,
        outroProcedimento: data.outro_procedimento || false,
        idApacOriginal: data.id_apac_original || null,
        dataSolicitacaoLaudo: data.data_solicitacao_laudo || null,
    };

    if (data.outro_procedimento) {
        dadosApac.procedimento.novoProcedimento = data.procedimento;
        dadosApac.procedimento.procedimento = OPCAO_OUTROS_PROCEDIMENTOS;
    } else {
        dadosApac.procedimento.procedimento = data.procedimento || null;
    }

    dadosApac.justificativa = {
        resumoAnamnese: data.resumo_exame_fisico || '',
        examesComplementares: data.exames_complementares || '',
        justificativaProcedimento: data.justificativa || '',
        cid10Principal: data.cid_10_principal ? [data.cid_10_principal] : [],
        cid10Secundario: data.cid_10_secundario ? [data.cid_10_secundario] : [],
    };

    dadosApac.anexos = [];

    if (data.anexos?.length) {
        //Há pelo menos um anexo já vinculado
        data.anexos.forEach((anexo) => {
            dadosApac.anexos.push({
                id: anexo.id,
                observacao: anexo.observacao || '',
                anexo: new File([''], anexo.nome),
                tipoAnexo: anexo.id_tipo_anexo || null,
            });
        });
    } else {
        dadosApac.anexos = [
            {
                id: null,
                anexo: null,
                tipoAnexo: { sigla: TIPO_ANEXO_SIGLA.EXAMES_OBRIGATORIOS },
                observacao: '',
            },
            {
                id: null,
                anexo: null,
                tipoAnexo: { sigla: TIPO_ANEXO_SIGLA.DEMAIS_DOCUMENTOS },
                observacao: '',
            },
        ];
    }
    dadosApac.anexosExcluidos = [];

    dadosApac.solicitante = data.solicitante || null;

    dadosApac.idFuncionarioPreenchedor = data.idFuncionarioPreenchedor || usuario.value.idFuncionario;

    return dadosApac;
};

const formatarDadosParaSalvamento = (dados) => {
    const formData = new FormData();

    if (dados.id) {
        formData.append('id', dados.id);
    }
    formData.append('situacao', dados.situacao);

    if (dados.paciente) {
        if (dados.paciente.cpf) {
            dados.paciente.cpf = dados.paciente.cpf.replace(/\D+/g, '');
        }
        if (dados.paciente.cep) {
            dados.paciente.cep = ('' + dados.paciente.cep).replace(/\D+/g, '');
        }
    }
    formData.append('paciente', JSON.stringify(dados.paciente));

    if (dados.procedimento?.procedencia) {
        formData.append('procedenciaProcedimento', dados.procedimento.procedencia);
    }

    if (dados.procedimento?.complementoProcedencia) {
        formData.append('complementoProcedencia', dados.procedimento.complementoProcedencia.trim());
    }

    if (dados.procedimento?.outroProcedimento) {
        if (dados.procedimento?.novoProcedimento) {
            formData.append('codigoProcedimento', dados.procedimento.novoProcedimento.codigo);
            formData.append('anoMesCompetenciaProcedimento', dados.procedimento.novoProcedimento.ano_mes_competencia);
        }
    } else {
        if (dados.procedimento?.procedimento) {
            formData.append('codigoProcedimento', dados.procedimento.procedimento.codigo);
            formData.append('anoMesCompetenciaProcedimento', dados.procedimento.procedimento.ano_mes_competencia);
        }
    }

    if (dados.procedimento?.instituicaoSolicitante?.id) {
        formData.append('idInstituicaoSolicitante', dados.procedimento.instituicaoSolicitante.id);
    }

    if (dados.procedimento?.idApacOriginal) {
        formData.append('idApacOriginal', dados.procedimento.idApacOriginal);
    }

    if (dados.procedimento?.instituicaoAutorizadora?.id) {
        formData.append('idInstituicaoAutorizadora', dados.procedimento.instituicaoAutorizadora.id);
    }

    if (dados.procedimento?.instituicaoExecutora?.id) {
        formData.append('idInstituicaoExecutora', dados.procedimento.instituicaoExecutora.id);
    }

    if (dados.procedimento?.quantidade !== null) {
        formData.append('quantidade', dados.procedimento.quantidade);
    }

    if (dados.procedimento?.dataSolicitacaoLaudo !== null) {
        formData.append('dataSolicitacaoLaudo', moment(dados.procedimento.dataSolicitacaoLaudo).format('YYYY-MM-DD'));
    }

    if (dados.procedimento?.tipoSolicitacao) {
        formData.append('tipo', dados.procedimento.tipoSolicitacao);
    }

    if (dados.procedimento?.outroProcedimento) {
        formData.append('outroProcedimento', dados.procedimento.outroProcedimento);
    }

    if (dados.justificativa.cid10Principal?.length) {
        const cid10 = dados.justificativa.cid10Principal[0];
        if (cid10.codigo_categoria) {
            formData.append('cid10PrincipalCategoria', cid10.codigo_categoria);
            formData.append('cid10PrincipalSubcategoria', cid10.codigo);
        } else {
            formData.append('cid10PrincipalCategoria', cid10.codigo);
        }
    }

    if (dados.justificativa.cid10Secundario?.length) {
        const cid10 = dados.justificativa.cid10Secundario[0];
        if (cid10.codigo_categoria) {
            formData.append('cid10SecundarioCategoria', cid10.codigo_categoria);
            formData.append('cid10SecundarioSubcategoria', cid10.codigo);
        } else {
            formData.append('cid10SecundarioCategoria', cid10.codigo);
        }
    }

    if (dados.justificativa.resumoAnamnese) {
        formData.append('resumoExameFisico', dados.justificativa.resumoAnamnese.trim());
    }

    if (dados.justificativa.examesComplementares) {
        formData.append('examesComplementares', dados.justificativa.examesComplementares.trim());
    }

    if (dados.justificativa.justificativaProcedimento) {
        formData.append('justificativa', dados.justificativa.justificativaProcedimento.trim());
    }

    if (dados.anexos?.length) {
        const dadosAnexos = [];
        dados.anexos.forEach((umAnexo, indice) => {
            if (umAnexo.anexo && umAnexo.anexo.name && umAnexo.tipoAnexo && umAnexo.tipoAnexo.id) {
                let observacao = null;
                if (umAnexo.observacao) {
                    observacao = umAnexo.observacao.trim().toUpperCase();
                }

                dadosAnexos.push({
                    id: umAnexo.id,
                    nome: umAnexo.anexo.name,
                    observacao: observacao,
                    id_apac_cadastro_tipo_anexo: umAnexo.tipoAnexo ? umAnexo.tipoAnexo.id : null,
                });
                formData.append(`anexo.${indice}`, umAnexo.anexo);
            }
        });
        formData.append('dadosAnexos', JSON.stringify(dadosAnexos));
    }

    if (dados.anexosExcluidos && dados.anexosExcluidos.length) {
        formData.append('dadosAnexosExcluidos', JSON.stringify(dados.anexosExcluidos));
    }

    if (dados.solicitante) {
        formData.append('idSolicitante', dados.solicitante.id);
    }

    if (dados.idFuncionarioPreenchedor) {
        formData.append('idFuncionarioPreenchedor', dados.idFuncionarioPreenchedor);
    }

    return formData;
};

const verificarErrosOrdemPrecedencia = (erros, secao) => {
    const ordemPrecedencia = ['paciente', 'procedimento', 'justificativa', 'anexos', 'solicitante'];
    for (const ordem of ordemPrecedencia) {
        if (secao === ordem) {
            return false;
        }

        if (erros[ordem]) {
            return true;
        }
    }
};

const verificarPossibilidadesProcesso = (dadosProcesso) => {
    const { situacaoProcesso, idFuncionarioPreenchedor } = dadosProcesso;

    let podeEditar = false;
    let podeSalvarRascunho = false;
    let podeEncaminhar = false;
    let podeDevolver = false;
    let podeAutorizar = false;
    let podeCancelar = false;
    let podeEditarPaciente = false;
    let podeEncaminharAutorizador = false;
    let podeAprovar = false;
    let podeInvalidar = false;
    let podeSolicitarNumeroApac = false;

    if (temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR)) {
        if (situacaoProcesso === situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR) {
            podeEncaminharAutorizador = true;
            podeEditar = true;
            podeDevolver = true;
        }

        if (situacaoProcesso === situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR) {
            podeEncaminharAutorizador = true;
        }

        if (situacaoProcesso === situacao.AUTORIZADO_PELO_MEDICO_AUTORIZADOR) {
            podeAprovar = true;
            podeDevolver = true;
        }

        if (situacaoProcesso === situacao.AUTORIZADO) {
            podeAprovar = true;
        }

        if (situacaoProcesso !== situacao.RASCUNHO) {
            podeInvalidar = true;
        }
    }

    if (temPerfilRBAC(usuario, PERFIL.APAC_SOLICITANTE)) {
        podeEditarPaciente = true;
        if (situacaoProcesso === situacao.RASCUNHO) {
            podeEditar = true;
            podeSalvarRascunho = true;
            podeEncaminhar = true;
        }

        if (situacaoProcesso === situacao.DEVOLVIDO) {
            podeEncaminhar = true;
        }

        if (situacaoProcesso !== situacao.CANCELADO) {
            podeCancelar = true;
        }

        if (situacaoProcesso === situacao.AUTORIZADO) {
            podeSolicitarNumeroApac = true;
        }
    }

    if (temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR)) {
        if (situacaoProcesso === situacao.RASCUNHO) {
            podeEditar = true;
            podeSalvarRascunho = true;
            podeEncaminhar = true;
            podeEditarPaciente = true;
        }

        if (situacaoProcesso === situacao.DEVOLVIDO && idFuncionarioPreenchedor === usuario.value.idFuncionario) {
            podeEditar = true;
            podeEncaminhar = true;
        }

        if (situacaoProcesso === situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR) {
            podeAutorizar = true;
            podeDevolver = true;
            podeEditar = true;
        }
    }

    if (temPerfilRBAC(usuario, PERFIL.APAC_ADMINISTRADOR)) {
        if (situacaoProcesso === situacao.RASCUNHO) {
            podeEditar = true;
            podeEncaminhar = true;
            podeSalvarRascunho = true;
            podeEditarPaciente = true;
        }

        if (situacaoProcesso === situacao.DEVOLVIDO) {
            podeEditar = true;
            podeEncaminhar = true;
        }

        if (situacaoProcesso === situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR) {
            podeDevolver = true;
            podeAutorizar = true;
        }

        if (situacaoProcesso === situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR) {
            podeDevolver = true;
            podeAutorizar = true;
        }
    }

    if (temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR)) {
        if (situacaoProcesso === situacao.RASCUNHO) {
            podeEditar = true;
            podeEncaminhar = true;
            podeSalvarRascunho = true;
            podeEditarPaciente = true;
        }

        if (situacaoProcesso === situacao.DEVOLVIDO) {
            podeEditar = true;
            podeEncaminhar = true;
        }

        if (situacaoProcesso === situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR) {
            podeDevolver = true;
            podeAutorizar = true;
        }

        if (situacaoProcesso === situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR) {
            podeDevolver = true;
            podeAutorizar = true;
        }
    }

    return {
        podeEditar,
        podeSalvarRascunho,
        podeEncaminhar,
        podeDevolver,
        podeAutorizar,
        podeCancelar,
        podeEditarPaciente,
        podeEncaminharAutorizador,
        podeAprovar,
        podeInvalidar,
        podeSolicitarNumeroApac,
    };
};

export default { verificarErrosOrdemPrecedencia, iniciarValoresSolicitacao, formatarDadosParaSalvamento, verificarPossibilidadesProcesso };
