import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttSeeButton,
    SttHidden,
    SttText,
    SttTranslateHook,
    SttBox,
} from '@stt-componentes/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { formatarSituacaoLabelCorProcesso, PERFIL } from '../../../common/Constants';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { verificarAvatarSituacao } from '../../util';
import { temPerfilRBAC } from '../../../security/acl';
import { usuario } from '../../../signals';
import Detalhes from './detalhes';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        marginTop: theme.spacing(2),
    },
    table: {
        overflow: 'auto',
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        height: theme.spacing(4),
        width: theme.spacing(13),
        '& span': {
            whiteSpace: 'pre-wrap',
        },
    },
    tableRowOriginal: {
        backgroundColor: '#ecf3fe !important',
    },

    arrowColor: {
        backgroundColor: '#5379C7',
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
}));

const TabelaHistorico = ({ row, index, processoOriginal, open: openPai, adicionarAba }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [open, setOpen] = useState(!processoOriginal);
    const classes = useStyles();

    return (
        <>
            <SttTableRow
                key={index}
                className={processoOriginal ? classes.tableRowOriginal : ''}
                style={{ display: openPai || processoOriginal ? 'table-center' : 'none' }}
            >
                <SttHidden mdDown>
                    <SttTableCell>
                        {row.processosContinuidade.length > 0 && (
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? (
                                    <Avatar className={classes.arrowColor}>
                                        <KeyboardArrowUpIcon />
                                    </Avatar>
                                ) : (
                                    <Avatar className={classes.arrowColor}>
                                        <KeyboardArrowDownIcon />
                                    </Avatar>
                                )}
                            </IconButton>
                        )}
                    </SttTableCell>
                    <SttTableCell>{row.sequencial_formatado}</SttTableCell>
                    <SttTableCell>{row.data_solicitacao}</SttTableCell>
                    <SttTableCell>{row.nome_instituicao_solicitante}</SttTableCell>
                    <SttTableCell>{row.nome_procedimento}</SttTableCell>
                    <SttTableCell>{row.codigo_procedimento}</SttTableCell>
                    <SttTableCell>{row.nome_paciente}</SttTableCell>
                    <SttTableCell>{row.cns_paciente}</SttTableCell>
                    <SttTableCell>{row.nome_funcionario_autorizador}</SttTableCell>
                    {temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR) ? (
                        <SttTableCell>{row.data_solicitacao_laudo}</SttTableCell>
                    ) : (
                        <SttTableCell>{row.data_autorizacao}</SttTableCell>
                    )}
                    <SttTableCell>
                        <Chip
                            className={classes.chip}
                            style={{ backgroundColor: formatarSituacaoLabelCorProcesso().COR[row.situacao] }}
                            avatar={verificarAvatarSituacao(row, strings)}
                            label={formatarSituacaoLabelCorProcesso().LABEL[row.situacao]}
                        />
                    </SttTableCell>
                </SttHidden>

                <SttHidden lgUp>
                    <SttTableCell width="96%">
                        {row.processosContinuidade.length > 0 && (
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? (
                                    <Avatar className={classes.arrowColor}>
                                        <KeyboardArrowUpIcon />
                                    </Avatar>
                                ) : (
                                    <Avatar className={classes.arrowColor}>
                                        <KeyboardArrowDownIcon />
                                    </Avatar>
                                )}
                            </IconButton>
                        )}
                        {row.sequencial_formatado && (
                            <SttText size="small">
                                <b>{strings.sequencial}:</b> {row.sequencial_formatado}
                            </SttText>
                        )}

                        {row.data_solicitacao_formatada && (
                            <SttText size="small">
                                <b>{strings.dataSolicitacao}:</b> {row.data_solicitacao_formatada}
                            </SttText>
                        )}

                        {row.nome_instituicao_solicitante && (
                            <SttText size="small">
                                <b>{strings.funcionarioSolicitante}:</b> {row.nome_instituicao_solicitante}
                            </SttText>
                        )}

                        {row.nome_procedimento && (
                            <SttText size="small">
                                <b>{strings.nomeProcedimento}:</b> {row.nome_procedimento}
                            </SttText>
                        )}

                        {row.codigo_procedimento && (
                            <SttText size="small">
                                <b>{strings.codigoProcedimento}:</b> {row.codigo_procedimento}
                            </SttText>
                        )}

                        {row.nome_paciente && (
                            <SttText size="small">
                                <b>{strings.nomePaciente}:</b> {row.nome_paciente}
                            </SttText>
                        )}

                        {row.cns_paciente && (
                            <SttText size="small">
                                <b>{strings.cnsPaciente}:</b> {row.cns_paciente}
                            </SttText>
                        )}

                        {row.nome_funcionario_autorizador && (
                            <SttText size="small">
                                <b>{strings.nomeAutorizador}:</b> {row.nome_funcionario_autorizador}
                            </SttText>
                        )}

                        {row.data_autorizacao && !(temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR)) && (
                            <SttText size="small">
                                <b>{strings.dataAutorizacao}:</b> {row.data_autorizacao}
                            </SttText>
                        )}

                        {row.data_solicitacao_laudo && (temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR)) && (
                            <SttText size="small">
                                <b>{strings.dataSolicitacaoLaudo}:</b> {row.data_solicitacao_laudo}
                            </SttText>
                        )}

                        <SttText size="small">
                            <Chip
                                avatar={verificarAvatarSituacao(row, strings)}
                                className={classes.chip}
                                style={{ border: '1px solid gray', backgroundColor: formatarSituacaoLabelCorProcesso().COR[row.situacao] }}
                                label={formatarSituacaoLabelCorProcesso().LABEL[row.situacao]}
                            />
                        </SttText>
                    </SttTableCell>
                </SttHidden>
                <SttTableCell>
                    <SttSeeButton
                        id={`btn-ver-solicitacao-${index}`}
                        onClick={() => {
                            adicionarAba({
                                titulo: `${strings.solicitacao} - ${row.sequencial_formatado}`,
                                conteudo: Detalhes,
                                id: row.id,
                                adicionarAba,
                                idAba: row.id,
                                esconderBotoes: true,
                            });
                        }}
                    />
                </SttTableCell>
            </SttTableRow>
            {open && (
                <SttTableRow className={processoOriginal ? classes.tableRowOriginal : ''}>
                    <SttHidden mdDown>
                        <SttTableCell colSpan={1}></SttTableCell>
                    </SttHidden>
                    <SttTableCell colSpan={10}>
                        <Collapse in={open} timeout="auto" unmountOnExit style={{}}>
                            <SttBox>
                                <SttHidden mdDown>
                                    <Typography>
                                        {row.processosContinuidade.length} {strings.solicitacoesContinuidade}
                                    </Typography>
                                </SttHidden>

                                <SttTable>
                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden mdDown>
                                                <SttTableCell align="center">{strings.numeral}</SttTableCell>
                                                <SttTableCell align="center">{strings.sequencial}</SttTableCell>
                                                <SttTableCell align="center">{strings.dataSolicitacao}</SttTableCell>
                                                <SttTableCell align="center">{strings.nomeAutorizador}</SttTableCell>
                                                <SttTableCell align="center">{strings.dataAutorizacao}</SttTableCell>
                                                <SttTableCell align="center">{strings.situacao}</SttTableCell>
                                                <SttTableCell align="center">{strings.visualizar}</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        {row.processosContinuidade.map((row_continuidade, index_row) => (
                                            <SttTableRow key={row_continuidade.id}>
                                                <SttHidden mdDown>
                                                    <SttTableCell align="center">{index_row + 1}</SttTableCell>
                                                    <SttTableCell align="center">{row_continuidade.sequencial_formatado}</SttTableCell>
                                                    <SttTableCell align="center">{row_continuidade.data_solicitacao}</SttTableCell>
                                                    <SttTableCell align="center">{row_continuidade.nome_funcionario_autorizador}</SttTableCell>
                                                    <SttTableCell align="center">{row_continuidade.data_autorizacao}</SttTableCell>
                                                    <SttTableCell align="center">
                                                        <Chip
                                                            className={classes.chip}
                                                            style={{ backgroundColor: formatarSituacaoLabelCorProcesso().COR[row.situacao] }}
                                                            avatar={verificarAvatarSituacao(row, strings)}
                                                            label={formatarSituacaoLabelCorProcesso().LABEL[row.situacao]}
                                                        />
                                                    </SttTableCell>
                                                    <SttTableCell align="center">
                                                        <SttSeeButton
                                                            id={`btn-ver-solicitacao-${index}`}
                                                            onClick={() => {
                                                                adicionarAba({
                                                                    titulo: `${strings.solicitacao} - ${row.sequencial_formatado}`,
                                                                    conteudo: Detalhes,
                                                                    id: row.id,
                                                                    adicionarAba,
                                                                    idAba: row.id,
                                                                    esconderBotoes: true,
                                                                });
                                                            }}
                                                        />
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttHidden lgUp>
                                                    <SttTableCell width="96%" colSpan={-1}>
                                                        <SttText size="small">
                                                            <b>{strings.sequencial}:</b> {row_continuidade.sequencial_formatado}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.funcionarioSolicitante}:</b> {row_continuidade.nome_funcionario_solicitante}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.sequencial}:</b> {row_continuidade.codigo_procedimento}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.sequencial}:</b> {row.nome_paciente}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.sequencial}:</b> {row.cns_paciente}
                                                        </SttText>
                                                    </SttTableCell>
                                                    <SttTableCell align="center">
                                                        <SttSeeButton
                                                            id={`btn-ver-solicitacao-${index}`}
                                                            onClick={() => {
                                                                adicionarAba({
                                                                    titulo: `${strings.solicitacao} - ${row.sequencial_formatado}`,
                                                                    conteudo: Detalhes,
                                                                    id: row.id,
                                                                    adicionarAba,
                                                                    idAba: row.id,
                                                                    esconderBotoes: true,
                                                                });
                                                            }}
                                                        />
                                                    </SttTableCell>
                                                </SttHidden>
                                            </SttTableRow>
                                        ))}
                                    </SttTableBody>
                                </SttTable>
                            </SttBox>
                        </Collapse>
                    </SttTableCell>
                    <SttHidden mdDown>
                        <SttTableCell colSpan={1}></SttTableCell>
                    </SttHidden>
                </SttTableRow>
            )}
        </>
    );
};

export default TabelaHistorico;
