import React, { Suspense, lazy, memo, useContext, useEffect, useRef } from 'react';
import { SttExpansionPanel, SttDivider, SttContainer, SttHeading, SttTranslateHook, SttCircularProgress, SttTabs, SttButton } from '@stt-componentes/core';
import { Formik } from 'formik';
import validationSchema from './validationSchema';
import { makeStyles } from '@material-ui/core/styles';
import { useSignals, useSignalEffect, useSignal } from '@preact/signals-react/runtime';
import { batch } from '@preact/signals-react';
import Functions from '../../../../common/Functions';
import alerta from '../../../../signals/alerta';
import carregando from '../../../../signals/carregando';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import { useNavigate, useLocation } from 'react-router-dom';
import { situacao } from '../../../../common/Constants';
import HttpStatus from 'http-status-codes';
import { instituicaoAutorizadoraSigtap, instituicaoSolicitanteSigtap, processo, valoresIniciais } from '../../../../signals/util';
import { usuario } from '../../../../signals';
import { FIELDS } from '../../../../componentes/solicitacao/procedimento/form/fieldNames';
import { verificarHistoricoProcesso } from '../../../../componentes/util';
import { historicoPaciente, parametrosHistoricoPaciente } from '../../../../signals/apac';
import { abaAtiva as abaAtivaPesquisa } from '../../../../signals/util';

const DadosPaciente = lazy(() => import('../../../../componentes/solicitacao/paciente'));
const Procedimento = lazy(() => import('../../../../componentes/solicitacao/procedimento'));
const Justificativa = lazy(() => import('../../../../componentes/solicitacao/justificativa'));
const Anexos = lazy(() => import('../../../../componentes/solicitacao/anexos'));
const Solicitante = lazy(() => import('../../../../componentes/solicitacao/solicitante'));

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(1.5),
    },
    header: {
        margin: '5vh',
    },
    buttonWrapper: {
        marginTop: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const Divider = memo((props) => {
    return <SttDivider {...props} />;
});

const SolicitacaoApac = () => {
    useSignals();
    const permissoesProcesso = useSignal({});
    const campos = useSignal({
        camposBusca: {
            cpf: { obrigatorio: false },
            cns: { obrigatorio: false },
            nome: { obrigatorio: false },
        },
        camposCadastro: [
            [
                { nome: 'nome', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
                { nome: 'genero', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
                { nome: 'dataNascimento', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
            ],
            [
                { nome: 'nomeMae', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
                { nome: 'cpf', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
                { nome: 'cns', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
            ],
            [
                { nome: 'cep', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
                { nome: 'numero', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
                { nome: 'logradouro', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            ],
            [
                { nome: 'bairro', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
                { nome: 'pais', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
                { nome: 'uf', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            ],
            [
                { nome: 'municipio', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
                { nome: 'email', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            ],
            [
                { nome: 'contato1', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
                { nome: 'contato2', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            ],
            [
                { nome: 'peso', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
                { nome: 'altura', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
                { nome: 'grupoSanguineo', obrigatorio: false, somenteLeitura: true, tamanho: { xs: 12, sm: 4, md: 4 } },
                { nome: 'fatorRh', obrigatorio: false, somenteLeitura: true, tamanho: { xs: 12, sm: 4, md: 4 } },
            ],
        ],
    });

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings, campos.value.camposCadastro);
    const classes = useStyles();
    const formRef = useRef();
    const APAC_API_BASE_URL = global.gConfig.url_base_apac;
    const timer = useSignal(null);
    const navigate = useNavigate();
    const initialValues = useSignal(null);
    const { state } = useLocation();
    const idSolicitacao = state?.idSolicitacao || null;
    const abas = useSignal([]);
    const abaAtiva = useSignal('0');

    useSignalEffect(() => {
        if (permissoesProcesso?.value) {
            campos.value = {
                camposBusca: {
                    cpf: { obrigatorio: false },
                    cns: { obrigatorio: false },
                    nome: { obrigatorio: false },
                },
                camposCadastro: [
                    [
                        { nome: 'nome', obrigatorio: true, somenteLeitura: !permissoesProcesso.value.podeEditarPaciente, tamanho: { xs: 12, sm: 6, md: 6 } },
                        { nome: 'genero', obrigatorio: true, somenteLeitura: !permissoesProcesso.value.podeEditarPaciente, tamanho: { xs: 12, sm: 3, md: 3 } },
                        {
                            nome: 'dataNascimento',
                            obrigatorio: true,
                            somenteLeitura: !permissoesProcesso.value.podeEditarPaciente,
                            tamanho: { xs: 12, sm: 3, md: 3 },
                        },
                    ],
                    [
                        { nome: 'nomeMae', obrigatorio: true, somenteLeitura: !permissoesProcesso.value.podeEditarPaciente, tamanho: { xs: 12, sm: 6, md: 6 } },
                        { nome: 'cpf', obrigatorio: true, somenteLeitura: true, tamanho: { xs: 12, sm: 3, md: 3 } },
                        { nome: 'cns', obrigatorio: true, somenteLeitura: true, tamanho: { xs: 12, sm: 3, md: 3 } },
                    ],
                    [
                        { nome: 'cep', obrigatorio: true, somenteLeitura: !permissoesProcesso.value.podeEditarPaciente, tamanho: { xs: 12, sm: 4, md: 4 } },
                        { nome: 'numero', obrigatorio: true, somenteLeitura: !permissoesProcesso.value.podeEditarPaciente, tamanho: { xs: 12, sm: 2, md: 2 } },
                        {
                            nome: 'logradouro',
                            obrigatorio: true,
                            somenteLeitura: !permissoesProcesso.value.podeEditarPaciente,
                            tamanho: { xs: 12, sm: 6, md: 6 },
                        },
                    ],
                    [
                        { nome: 'bairro', obrigatorio: true, somenteLeitura: !permissoesProcesso.value.podeEditarPaciente, tamanho: { xs: 12, sm: 6, md: 6 } },
                        { nome: 'pais', obrigatorio: true, somenteLeitura: !permissoesProcesso.value.podeEditarPaciente, tamanho: { xs: 12, sm: 4, md: 4 } },
                        { nome: 'uf', obrigatorio: true, somenteLeitura: !permissoesProcesso.value.podeEditarPaciente, tamanho: { xs: 12, sm: 2, md: 2 } },
                    ],
                    [
                        {
                            nome: 'municipio',
                            obrigatorio: true,
                            somenteLeitura: !permissoesProcesso.value.podeEditarPaciente,
                            tamanho: { xs: 12, sm: 6, md: 6 },
                        },
                        { nome: 'email', obrigatorio: false, somenteLeitura: !permissoesProcesso.value.podeEditarPaciente, tamanho: { xs: 12, sm: 6, md: 6 } },
                    ],
                    [
                        {
                            nome: 'contato1',
                            obrigatorio: false,
                            somenteLeitura: !permissoesProcesso.value.podeEditarPaciente,
                            tamanho: { xs: 12, sm: 6, md: 6 },
                        },
                        {
                            nome: 'contato2',
                            obrigatorio: false,
                            somenteLeitura: !permissoesProcesso.value.podeEditarPaciente,
                            tamanho: { xs: 12, sm: 6, md: 6 },
                        },
                    ],
                ],
            };
        }
    });

    useSignalEffect(() => {
        if (idSolicitacao) {
            iniciarSolicitacao(idSolicitacao);
        } else {
            iniciarSolicitacao();
        }
    });

    useSignalEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer.value);
            }
        };
    });

    const iniciarSolicitacao = (idSolicitacao) => {
        carregando.value = {
            open: true,
            text: strings.iniciandoSolicitacao,
        };

        resetarDados();

        if (idSolicitacao) {
            axios
                .get(`${global.gConfig.url_base_apac}/solicitacao/${idSolicitacao}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { data } = response.data;
                        initialValues.value = Functions.iniciarValoresSolicitacao(data);
                        permissoesProcesso.value = Functions.verificarPossibilidadesProcesso({
                            situacaoProcesso: parseInt(initialValues.value.situacao),
                            idFuncionarioPreenchedor: initialValues.value.id_funcionario_preenchedor,
                        });
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    carregando.value = {
                        open: false,
                        text: '',
                    };
                });
            return;
        }

        initialValues.value = Functions.iniciarValoresSolicitacao({});
        permissoesProcesso.value = Functions.verificarPossibilidadesProcesso({
            situacaoProcesso: parseInt(initialValues.value.situacao),
            idFuncionarioPreenchedor: usuario.value.idFuncionario,
        });
        carregando.value = {
            open: false,
            text: '',
        };
    };

    const submitForm =
        (rascunho, editarProcesso) =>
        (dados, { setSubmitting }) => {
            setSubmitting(false);

            if (formRef.current) {
                let mensagemConfirmacao;
                if (rascunho) {
                    mensagemConfirmacao = strings.textoConfirmarRascunhoSolicitacao;
                } else if (editarProcesso) {
                    mensagemConfirmacao = strings.textoConfirmarEdicaoSolicitacao;
                } else {
                    mensagemConfirmacao = strings.textoConfirmarEnvio;
                }

                batch(() => {
                    alerta.value = {
                        ...alerta.value,
                        open: true,
                        title: strings.atencao,
                        type: 'alert',
                        message: mensagemConfirmacao,
                        options: [
                            {
                                title: strings.confirmar,
                                onClick: () => {
                                    enviarForm(dados, rascunho, editarProcesso, setSubmitting);
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false,
                                    };
                                },
                            },
                            {
                                title: strings.cancelar,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false,
                                    };
                                },
                            },
                        ],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false,
                            };
                        },
                    };
                });
            }
        };

    const enviarForm = (dados, rascunho, editarProcesso, setSubmitting = null) => {
        const payload = Functions.formatarDadosParaSalvamento(dados);

        if (setSubmitting) {
            setSubmitting(true);
            carregando.value = {
                open: true,
                text: strings.salvando,
            };
        }

        payload.append('rascunho', !!rascunho);

        let url;
        if (rascunho || editarProcesso) {
            url = `${APAC_API_BASE_URL}/solicitacao/editar`;
        }

        if (!rascunho && !editarProcesso) {
            url = `${APAC_API_BASE_URL}/solicitacao/encaminhar`;
        }

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => {};

        axios
            .post(url, payload, {
                headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {
                const { data } = response;
                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                let msgPadrao = rascunho ? strings.rascunhoSalvoSucesso : strings.solicitacaoEnviada;
                mensagemAlerta = data?.message || msgPadrao;
                options = [
                    {
                        title: strings.ok,
                        onClick: () => {
                            handleRetornarInicio();
                            alerta.value = {
                                ...alerta.value,
                                open: false,
                            };
                        },
                    },
                ];
                onClose = () => {
                    handleRetornarInicio();
                    alerta.value = {
                        ...alerta.value,
                        open: false,
                    };
                };

                alerta.value = {
                    open: true,
                    title: tituloAlerta,
                    type: 'success',
                    message: mensagemAlerta,
                    options: options,
                    onClose: onClose,
                };
            })
            .catch((err) => {
                const { response } = err;
                let mensagem = strings.mensagemErroGeral;

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach((error) => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem && arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }
                alerta.value = {
                    open: true,
                    title: strings.erro,
                    type: 'error',
                    message: mensagem,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false,
                                };
                            },
                        },
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false,
                        };
                    },
                };
            })
            .finally(() => {
                carregando.value = {
                    open: false,
                    text: '',
                };

                setSubmitting(false);
            });
    };

    const handleRetornarInicio = () => {
        abaAtivaPesquisa.value = '0';
        resetarDados();
        navigate('/');
    };

    const resetarDados = () => {
        valoresIniciais.value = null;
        instituicaoSolicitanteSigtap.value = [];
        instituicaoAutorizadoraSigtap.value = [];
        historicoPaciente.value = {};
        parametrosHistoricoPaciente.value = {};
    };

    const onSubmitRascunho = submitForm(true, false);
    const onSubmit = submitForm(false, false);
    const onSubmitEditarProcesso = submitForm(false, true);

    const adicionarAba = (aba) => {
        let dadosAbas = [...abas.value];

        let { idAba } = aba;

        // Controle para evitar adição de aba repetida
        if (!dadosAbas.some((a) => a.idAba === idAba)) {
            dadosAbas.push(aba);
            abas.value = dadosAbas;
        }

        setTimeout(() => {
            abaAtiva.value = dadosAbas.findIndex((a) => a.idAba === idAba).toString();
        }, 120);
    };

    const removerAba = (indice) => {
        abaAtiva.value = '0';
        const i = indice[0];

        const dadosAbas = [...abas.value.slice(0, i), ...abas.value.slice(i + 1)];

        setTimeout(() => {
            abas.value = dadosAbas;
        }, 120);
    };

    const removerAbaId = (idAba) => {
        const index = abas.value.findIndex((a) => a.idAba === idAba);
        if (index !== -1) {
            removerAba([index]);
        }
    };

    useEffect(() => {
        abas.value = [
            {
                titulo: strings.solicitacao,
                conteudo: Solicitacao,
                key: 'Solicitacao',
                adicionarAba,
                removerAbaId,
                permanente: true,
            },
        ];
    }, []);

    const Solicitacao = () => {
        return (
            <>
                {initialValues.value && (
                    <Formik innerRef={formRef} initialValues={initialValues.value} validationSchema={schema} onSubmit={onSubmit}>
                        {({ values, isSubmitting, setSubmitting, handleSubmit, errors }) => {
                            return (
                                <SttContainer>
                                    <form onSubmit={handleSubmit} noValidate>
                                        <SttHeading variant="h1" color="primary" align="center" className={classes.header}>
                                            {values.situacao === 0 ? strings.solicitacaoNovoApac : strings.alteracaoApac}
                                        </SttHeading>
                                        <SttExpansionPanel
                                            title={strings.dadosPaciente}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <DadosPaciente
                                                        campos={campos.value}
                                                        podeEditarPaciente={permissoesProcesso.value.podeEditarPaciente}
                                                        adicionarAba={adicionarAba}
                                                        abas={abas}
                                                    />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />

                                        <SttExpansionPanel
                                            title={strings.procedimento}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Procedimento />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />

                                        <SttExpansionPanel
                                            title={strings.justificativa}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Justificativa />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        <SttExpansionPanel
                                            title={strings.anexos}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Anexos />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        <SttExpansionPanel
                                            title={strings.solicitante}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Solicitante />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />

                                        <div className={classes.buttonWrapper}>
                                            {permissoesProcesso.value.podeSalvarRascunho && (
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.button}
                                                    disabled={isSubmitting}
                                                    onClick={() => onSubmitRascunho(values, { setSubmitting })}
                                                >
                                                    {strings.salvarRascunho}
                                                </SttButton>
                                            )}

                                            {permissoesProcesso.value.podeEncaminhar && (
                                                <SttButton type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                                    {strings.encaminhar}
                                                </SttButton>
                                            )}

                                            {permissoesProcesso.value.podeEditar && values.situacao !== situacao.RASCUNHO && (
                                                <SttButton
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => onSubmitEditarProcesso(values, { setSubmitting })}
                                                >
                                                    {strings.salvar}
                                                </SttButton>
                                            )}
                                        </div>
                                    </form>
                                </SttContainer>
                            );
                        }}
                    </Formik>
                )}
            </>
        );
    };

    return (
        <SttTabs
            abas={abas.value}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={(abaNova) => (abaAtiva.value = abaNova)}
            abaAtiva={abaAtiva.value}
            handleCloseTab={(indice) => removerAba(indice)}
            permanente={true}
            exigeConfirmacaoClose={false}
        />
    );
};
export default SolicitacaoApac;
