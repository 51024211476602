export const CNES_INSTITUICAO_SOLICITANTE = 'cnes_instituicao_solicitante';
export const NOME_AUTORIZADOR = 'nome_autorizador';
export const DATA_INICIAL_SOLICITACAO = 'data_inicial_solicitacao';
export const DATA_FINAL_SOLICITACAO = 'data_final_solicitacao';
export const DATA_INICIAL_AUTORIZACAO = 'data_inicial_autorizacao';
export const DATA_FINAL_AUTORIZACAO = 'data_final_autorizacao';
export const NOME_INSTITUICAO_SOLICITANTE = 'nome_instituicao_solicitante';
export const NOME_FUNCIONARIO_AUTORIZADOR = 'nome_funcionario_autorizador';
export const INSTITUICAO_AUTORIZADORA = 'instituicao_autorizadora';
export const NOME_PACIENTE = 'nome_paciente';
export const CNS_PACIENTE = 'cns_paciente';
export const CPF_PACIENTE = 'cpf_paciente';
export const DESCRICAO_PROCEDIMENTO = 'descricao_procedimento';
export const CODIGO_PROCEDIMENTO = 'codigo_procedimento';
export const QUANTIDADE = 'quantidade';
export const SITUACAO = 'situacao';
export const DATA_INICIAL_SOLICITACAO_LAUDO = 'data_inicial_solicitacao_laudo';
export const DATA_FINAL_SOLICITACAO_LAUDO = 'data_final_solicitacao_laudo';
export const NUMERO_APAC = 'numero_apac';
