import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Form from './form';
import TableContainer from '@material-ui/core/TableContainer';
import Detalhes from './detalhes';
import carregando from '../../../signals/carregando';
import { makeStyles } from '@material-ui/core/styles';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { batch, computed } from '@preact/signals-react';
import { parametrosHistoricoPaciente, pesquisarProcessos } from '../../../signals/apac';
import { Util } from '@stt-utilitarios/core';
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
    SttText,
    SttTranslateHook,
    SttMenu,
    SttCheckbox,
    SttButton,
    SttMenuItem,
    SttListItemIcon,
    SttListItemText,
} from '@stt-componentes/core';
import { PERFIL, TIPO_OPERACAO, formatarSituacaoLabelCorProcesso, situacao } from '../../../common/Constants';
import { temPerfilRBAC } from '../../../security/acl';
import { usuario } from '../../../signals';
import Operacoes from '../../operacao';
import { verificarArraySubsetProcessosEncaminhamento, verificarAvatarSituacao } from '../../util';
import { Chip, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { v4 as uuidv4 } from 'uuid';
import historico from '../../pesquisa/apac/historico';
import { abaAtiva, buscarHistorico, recarregarPesquisa } from '../../../signals/util';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        marginTop: theme.spacing(2),
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        height: theme.spacing(4),
        width: theme.spacing(13),
        '& span': {
            whiteSpace: 'pre-wrap',
        },
    },
    tableCell: {
        textAlign: 'start',
    },
    heading: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        textAlign: 'center',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc',
    },
    CAMPOS: {
        SEQUENCIAL: 'sequencial',
        DATA_SOLICITACAO: 'data_solicitacao',
        NOME_PROCEDIMENTO: 'nome_procedimento',
        CODIGO_PROCEDIMENTO: 'codigo_procedimento',
        NOME_INSTITUICAO_SOLICITANTE: 'nome_instituicao_solicitante',
        CIDADE_SOLICITANTE: 'cidade_solicitante',
        NOME_PACIENTE: 'nome_paciente',
        CPF_PACIENTEE: 'cpf_paciente',
        CNS_PACIENTE: 'cns_paciente',
        DATA_AUTORIZACAO: 'data_autorizacao',
        NOME_FUNCIONARIO_AUTORIZADOR: 'nome_funcionario_autorizador',
        SITUACAO: 'situacao',
        NOME_INSTITUICAO_EXECUTORA: 'nome_instituicao_executora',
        DATA_SOLICITACAO_LAUDO: 'data_solicitacao_laudo',
    },
};

const PesquisaApac = ({ adicionarAba, removerAbaId, abas }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const processos = useSignal([]);
    const totalRegistros = useSignal(0);
    const start = computed(() => page.value * count.value);
    const count = useSignal(null);
    const page = useSignal(null);
    const order = useSignal(ORDENACAO.DIRECAO.ASC);
    const orderBy = useSignal(null);
    const processosSelecionados = useSignal([]);
    const encaminharAutorizador = useSignal(false);
    const outrosDados = useSignal({});
    const tipoOperacao = useSignal(null);
    const idApacSelecionado = useSignal(null);
    const opcoes = useSignal([]);

    useEffect(() => {
        pesquisarProcessos.value = true;

        // Tratar os menus para os processos
        let menu = [];
        menu.push({
            icon: VisibilityIcon,
            title: strings.ver,
            createOnClick: (row) => {
                // Adicionar a aba de histórico daquele paciente (somente se o usuário não for solicitante)
                if (!temPerfilRBAC(usuario, PERFIL.APAC_SOLICITANTE)) {
                    parametrosHistoricoPaciente.value.idPaciente = row.id_paciente;
                    buscarHistorico.value = true;
                    adicionarAba({
                        titulo: `${strings.historico} - ${row.nome_paciente}`,
                        conteudo: historico,
                        adicionarAba,
                        abas: abas,
                        idAba: `historico_${row.id_paciente}`,
                        contextMenu: [
                            {
                                titulo: strings.fecharTodosHistoricos,
                                acao: () => {
                                    abas.value.forEach((aba) => {
                                        if (!aba.permanente && aba.idAba && aba.idAba.toString().includes('historico')) {
                                            removerAbaId(aba.idAba);
                                        }
                                    });
                                },
                            },
                            {
                                titulo: strings.fecharTodasGuias,
                                acao: () => {
                                    abas.value.forEach((aba) => {
                                        if (!aba.permanente) {
                                            removerAbaId(aba.idAba);
                                        }
                                    });
                                },
                            },
                            {
                                titulo: strings.fecharOutrasGuias,
                                acao: () => {
                                    abas.value.forEach((aba) => {
                                        if (!aba.permanente && aba.idAba !== `historico_${row.id_paciente}`) {
                                            removerAbaId(aba.idAba);
                                        }
                                    });
                                },
                            },
                        ],
                    });
                }

                // Adicionar a aba de visualizacao daquele processo
                adicionarAba({
                    titulo: `${strings.solicitacao} ${row.sequencial_formatado}`,
                    conteudo: Detalhes,
                    id: row.id,
                    adicionarAba,
                    idAba: row.id,
                    voltar: handleClickVoltar,
                    abas: abas,
                    contextMenu: [
                        {
                            titulo: strings.fecharTodosHistoricos,
                            acao: () => {
                                abas.value.forEach((aba) => {
                                    if (!aba.permanente && aba.idAba && aba.idAba.toString().includes('historico')) {
                                        removerAbaId(aba.idAba);
                                    }
                                });
                            },
                        },
                        {
                            titulo: strings.fecharTodasGuias,
                            acao: () => {
                                abas.value.forEach((aba) => {
                                    if (!aba.permanente) {
                                        removerAbaId(aba.idAba);
                                    }
                                });
                            },
                        },
                        {
                            titulo: strings.fecharOutrasGuias,
                            acao: () => {
                                abas.value.forEach((aba) => {
                                    if (!aba.permanente && aba.idAba !== row.id) {
                                        removerAbaId(aba.idAba);
                                    }
                                });
                            },
                        },
                    ],
                });

                idApacSelecionado.value = row.id;
            },
            showFunction: () => {
                return true;
            },
        });

        opcoes.value = menu;
    }, []);

    useSignalEffect(() => {
        if (abaAtiva.value == '0' && recarregarPesquisa.value) {
            recarregarPesquisa.value = false;
            pesquisarProcessos.value = true;
        }
    });

    useSignalEffect(() => {
        batch(() => {
            page.value = global.gConfig.pagination.start;
            count.value = global.gConfig.pagination.count;
            pesquisarProcessos.value = true;
        });
    });

    const handleClickSort = (campo) => {
        const isAsc = orderBy.value === campo && order.value === ORDENACAO.DIRECAO.ASC;
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        batch(() => {
            order.value = newOrder;
            orderBy.value = campo;
            pesquisarProcessos.value = true;
        });
    };

    const handleChangePage = (event, newPage) => {
        batch(() => {
            page.value = newPage;
            pesquisarProcessos.value = true;
        });
    };

    const handleChangeRowsPerPage = (event) => {
        batch(() => {
            count.value = event.target.value;
            page.value = global.gConfig.pagination.start;
            pesquisarProcessos.value = true;
        });
    };

    const resetPageOrder = () => {
        batch(() => {
            page.value = global.gConfig.pagination.start;
            count.value = global.gConfig.pagination.count;
            order.value = ORDENACAO.DIRECAO.DESC;
            orderBy.value = ORDENACAO.CAMPOS.SEQUENCIAL;
        });
    };

    const callbackBusca = (dados) => {
        batch(() => {
            totalRegistros.value = parseInt(dados.totalRegistros);
            processos.value = formatarDadosPesquisa(dados.itens);
            pesquisarProcessos.value = false;
        });
    };

    const formatarDadosPesquisa = (dados) => {
        dados.map((dado) => {
            if (dado.cpf_paciente) {
                dado.cpf_paciente = ('00000000000' + dado.cpf_paciente.replace(/[\. ,:-]+/g, '')).slice(-11);
                dado.cpf_paciente = Util.util.adicionarMascara(dado.cpf_paciente, '000.000.000-00');
            }

            if (dado.nome_instituicao_solicitante && dado.cnes_instituicao_solicitante) {
                dado.nome_instituicao_solicitante =
                    dado.nome_instituicao_solicitante.charAt(0).toUpperCase() + dado.nome_instituicao_solicitante.slice(1).toLowerCase();
                dado.nome_instituicao_solicitante = `${dado.nome_instituicao_solicitante} (CNES: ${dado.cnes_instituicao_solicitante})`;
            }

            if (dado.nome_procedimento) {
                dado.nome_procedimento = dado.nome_procedimento.charAt(0).toUpperCase() + dado.nome_procedimento.slice(1).toLowerCase();
            }

            if (dado.nome_paciente) {
                dado.nome_paciente = dado.nome_paciente.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
            }

            if (dado.nome_funcionario_autorizador) {
                dado.nome_funcionario_autorizador =
                    dado.nome_funcionario_autorizador.charAt(0).toUpperCase() + dado.nome_funcionario_autorizador.slice(1).toLowerCase();
            }
        });
        return dados;
    };

    const callbackLimpar = () => {
        batch(() => {
            count.value = global.gConfig.pagination.count;
            page.value = global.gConfig.pagination.start;
            order.value = ORDENACAO.DIRECAO.ASC;
            orderBy.value = null;
            processos.value = [];
            totalRegistros.value = 0;
            processosSelecionados.value = [];
            outrosDados.value = {};
            tipoOperacao.value = null;
        });
    };

    const handleClickVoltar = (atualizarBusca) => {
        pesquisarProcessos.value = !!atualizarBusca;
        if (idApacSelecionado.value) {
            removerAbaId(idApacSelecionado.value);
            idApacSelecionado.value = null;
        }
    };

    const selecionarTodosProcessosPendentes = (selecionar) => {
        if (!processos.value.length) {
            return;
        }
        const processosPagina = processos.value.map((e) => {
            if (
                e.id &&
                (e.situacao === situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR ||
                    (e.situacao === situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR && e.outro_procedimento))
            ) {
                return e;
            }
        });

        if (selecionar) {
            const selecionados = [...processosSelecionados.value, ...processosPagina];
            processosSelecionados.value = [...selecionados.filter((value, index, array) => array.indexOf(value) === index)];
            processosSelecionados.value = [...processosSelecionados.value.filter((processo) => processo)];
        } else {
            const selecionados = processosSelecionados.value.filter((e) => !processosPagina.includes(e));
            processosSelecionados.value = [...selecionados];
        }
    };

    const preEncaminharAutorizador = () => {
        outrosDados.value = {
            idInstituicaoAutorizadora: processosSelecionados.value[0].id_instituicao_autorizadora,
        };

        tipoOperacao.value = TIPO_OPERACAO.ENCAMINHAR_AUTORIZADOR;

        encaminharAutorizador.value = true;
    };

    return (
        <>
            <Form
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                start={start}
                count={count}
                page={page}
                orderBy={orderBy}
                order={order}
                resetPageOrder={resetPageOrder}
            />
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    {processosSelecionados.value.length > 0 && temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) && (
                        <caption>
                            <div className={classes.contentWrapper}>
                                {processosSelecionados.value.length === 1 && `${processosSelecionados.value.length} ${strings.exameSelecionado}`}
                                {processosSelecionados.value.length > 1 && `${processosSelecionados.value.length} ${strings.processosSelecionados}`}
                                <SttButton type="button" variant="contained" color="secondary" onClick={() => preEncaminharAutorizador()}>
                                    {strings.encaminharAutorizador}
                                </SttButton>
                            </div>
                        </caption>
                    )}
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden mdDown>
                                {temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) && (
                                    <SttTableCell>
                                        <SttCheckbox
                                            color="primary"
                                            checked={
                                                processos.value.length > 0 &&
                                                verificarArraySubsetProcessosEncaminhamento(
                                                    processos.value.filter(
                                                        (p) =>
                                                            (p.id && p.situacao === situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR) ||
                                                            (p.situacao === situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR && p.outro_procedimento)
                                                    ),
                                                    processosSelecionados.value
                                                )
                                            }
                                            onChange={(_, value) => selecionarTodosProcessosPendentes(value)}
                                        />
                                    </SttTableCell>
                                )}

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.NOME_INSTITUICAO_SOLICITANTE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NOME_INSTITUICAO_SOLICITANTE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NOME_INSTITUICAO_SOLICITANTE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME_INSTITUICAO_SOLICITANTE)}
                                    >
                                        {strings.instituicaoSolicitante}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell
                                    className={classes.tableCell}
                                    sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : false}
                                >
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_SOLICITACAO)}
                                    >
                                        {strings.dataSolicitacao}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.CODIGO_PROCEDIMENTO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.CODIGO_PROCEDIMENTO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.CODIGO_PROCEDIMENTO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CODIGO_PROCEDIMENTO)}
                                    >
                                        {strings.codigoProcedimento}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.NOME_PROCEDIMENTO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NOME_PROCEDIMENTO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NOME_PROCEDIMENTO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME_PROCEDIMENTO)}
                                    >
                                        {strings.nomeProcedimento}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.NOME_PACIENTE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NOME_PACIENTE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NOME_PACIENTE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME_PACIENTE)}
                                    >
                                        {strings.nomePaciente}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                {(temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR)) && (
                                    <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.NOME_INSTITUICAO_EXECUTORA ? order.value : false}>
                                        <SttTableSortLabel
                                            active={orderBy.value === ORDENACAO.CAMPOS.NOME_INSTITUICAO_EXECUTORA}
                                            direction={orderBy.value === ORDENACAO.CAMPOS.NOME_INSTITUICAO_EXECUTORA ? order.value : ORDENACAO.DIRECAO.ASC}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME_INSTITUICAO_EXECUTORA)}
                                        >
                                            {strings.instituicaoExecutora}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                )}

                                <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.NOME_FUNCIONARIO_AUTORIZADOR ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NOME_FUNCIONARIO_AUTORIZADOR}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NOME_FUNCIONARIO_AUTORIZADOR ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME_FUNCIONARIO_AUTORIZADOR)}
                                    >
                                        {strings.nomeAutorizador}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                {temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR) ? (
                                    <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO_LAUDO ? order.value : false}>
                                        <SttTableSortLabel
                                            active={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO_LAUDO}
                                            direction={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO_LAUDO ? order.value : ORDENACAO.DIRECAO.ASC}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_SOLICITACAO_LAUDO)}
                                        >
                                            {strings.dataSolicitacaoLaudo}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                ) : (
                                    <SttTableCell sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_AUTORIZACAO ? order.value : false}>
                                        <SttTableSortLabel
                                            active={orderBy.value === ORDENACAO.CAMPOS.DATA_AUTORIZACAO}
                                            direction={orderBy.value === ORDENACAO.CAMPOS.DATA_AUTORIZACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_AUTORIZACAO)}
                                        >
                                            {strings.dataAutorizacao}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                )}

                                <SttTableCell className={classes.tableCell} sortDirection={orderBy.value === ORDENACAO.CAMPOS.SITUACAO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.SITUACAO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.SITUACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SITUACAO)}
                                    >
                                        {strings.situacao}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell>{strings.visualizar}</SttTableCell>
                            </SttHidden>
                            <SttHidden lgUp>
                                {temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) && (
                                    <SttTableCell>
                                        <SttCheckbox
                                            color="primary"
                                            checked={
                                                processos.value.length > 0 &&
                                                verificarArraySubsetProcessosEncaminhamento(
                                                    processos.value.filter(
                                                        (p) =>
                                                            (p.id && p.situacao === situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR) ||
                                                            (p.situacao === situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR && p.outro_procedimento)
                                                    ),
                                                    processosSelecionados.value
                                                )
                                            }
                                            onChange={(_, value) => selecionarTodosProcessosPendentes(value)}
                                        />
                                    </SttTableCell>
                                )}
                                <SttTableCell colSpan="2">{strings.pesquisaAPAC}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {!carregando.value.open &&
                            (processos.value.length === 0 ? (
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={12} align="center">
                                        {strings.nenhumRegistroEncontrado}
                                    </SttTableCell>
                                </SttTableRow>
                            ) : (
                                processos.value.map((row, index) => (
                                    <SttTableRow key={index}>
                                        <SttHidden mdDown>
                                            {temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) && (
                                                <SttTableCell>
                                                    <SttCheckbox
                                                        color="primary"
                                                        disabled={
                                                            !(
                                                                row.situacao === situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR ||
                                                                (row.situacao === situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR && row.outro_procedimento)
                                                            )
                                                        }
                                                        checked={processosSelecionados.value.some((p) => p.id === row.id)}
                                                        onChange={(_, value) => {
                                                            if (value) {
                                                                processosSelecionados.value = [...processosSelecionados.value, row];
                                                            } else {
                                                                processosSelecionados.value = processosSelecionados.value.filter((e) => e.id !== row.id);
                                                            }
                                                        }}
                                                    />
                                                </SttTableCell>
                                            )}
                                            <SttTableCell className={classes.tableCell}>{row.nome_instituicao_solicitante}</SttTableCell>
                                            <SttTableCell className={classes.tableCell}>{row.data_solicitacao_formatada}</SttTableCell>
                                            <SttTableCell className={classes.tableCell}>{row.codigo_procedimento}</SttTableCell>
                                            <SttTableCell className={classes.tableCell}>{row.nome_procedimento}</SttTableCell>
                                            <SttTableCell className={classes.tableCell}>{row.nome_paciente}</SttTableCell>
                                            {(temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR)) && (
                                                <SttTableCell className={classes.tableCell}>{row.nome_instituicao_executora}</SttTableCell>
                                            )}
                                            <SttTableCell className={classes.tableCell}>{row.nome_funcionario_autorizador}</SttTableCell>
                                            {temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR) ? (
                                                <SttTableCell className={classes.tableCell}>{row.data_solicitacao_laudo}</SttTableCell>
                                            ) : (
                                                <SttTableCell className={classes.tableCell}>{row.data_autorizacao}</SttTableCell>
                                            )}
                                            <SttTableCell className={classes.tableCell}>
                                                <Chip
                                                    avatar={verificarAvatarSituacao(row, strings)}
                                                    className={classes.chip}
                                                    style={{ backgroundColor: formatarSituacaoLabelCorProcesso().COR[row.situacao] }}
                                                    label={formatarSituacaoLabelCorProcesso().LABEL[row.situacao]}
                                                />
                                            </SttTableCell>
                                        </SttHidden>

                                        <SttHidden lgUp>
                                            <SttTableCell width="96%">
                                                {temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) && (
                                                    <SttTableCell>
                                                        <SttCheckbox
                                                            color="primary"
                                                            disabled={
                                                                !(
                                                                    row.situacao === situacao.AGUARDANDO_ENCAMINHAMENTO_OPERADOR ||
                                                                    (row.situacao === situacao.AGUARDANDO_AVALIACAO_AUTORIZADOR && row.outro_procedimento)
                                                                )
                                                            }
                                                            checked={processosSelecionados.value.some((p) => p.id === row.id)}
                                                            onChange={(_, value) => {
                                                                if (value) {
                                                                    processosSelecionados.value = [...processosSelecionados.value, row];
                                                                } else {
                                                                    processosSelecionados.value = processosSelecionados.value.filter((e) => e.id !== row.id);
                                                                }
                                                            }}
                                                        />
                                                    </SttTableCell>
                                                )}

                                                {row.nome_instituicao_solicitante && (
                                                    <SttText size="small">
                                                        <b>{strings.instituicaoSolicitante}:</b> {row.nome_instituicao_solicitante}
                                                    </SttText>
                                                )}

                                                {row.data_solicitacao_formatada && (
                                                    <SttText size="small">
                                                        <b>{strings.dataSolicitacao}:</b> {row.data_solicitacao_formatada}
                                                    </SttText>
                                                )}

                                                {row.codigo_procedimento && (
                                                    <SttText size="small">
                                                        <b>{strings.codigoProcedimento}:</b> {row.codigo_procedimento}
                                                    </SttText>
                                                )}

                                                {row.nome_procedimento && (
                                                    <SttText size="small">
                                                        <b>{strings.nomeProcedimento}:</b> {row.nome_procedimento}
                                                    </SttText>
                                                )}

                                                {row.nome_paciente && (
                                                    <SttText size="small">
                                                        <b>{strings.nomePaciente}:</b> {row.nome_paciente}
                                                    </SttText>
                                                )}

                                                {row.nome_instituicao_executora &&
                                                    (temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR)) && (
                                                        <SttText size="small">
                                                            <b>{strings.instituicaoExecutora}:</b> {row.nome_instituicao_executora}
                                                        </SttText>
                                                    )}

                                                {row.nome_funcionario_autorizador && (
                                                    <SttText size="small">
                                                        <b>{strings.nomeAutorizador}:</b> {row.nome_funcionario_autorizador}
                                                    </SttText>
                                                )}

                                                {row.data_autorizacao &&
                                                    !(temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR)) && (
                                                        <SttText size="small">
                                                            <b>{strings.dataAutorizacao}:</b> {row.data_autorizacao}
                                                        </SttText>
                                                    )}

                                                {row.data_solicitacao_laudo &&
                                                    (temPerfilRBAC(usuario, PERFIL.APAC_OPERADOR) || temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR)) && (
                                                        <SttText size="small">
                                                            <b>{strings.dataSolicitacaoLaudo}:</b> {row.data_solicitacao_laudo}
                                                        </SttText>
                                                    )}

                                                {row.situacao != null && (
                                                    <SttText size="small">
                                                        <Chip
                                                            avatar={verificarAvatarSituacao(row, strings)}
                                                            className={classes.chip}
                                                            style={{ backgroundColor: formatarSituacaoLabelCorProcesso().COR[row.situacao] }}
                                                            label={formatarSituacaoLabelCorProcesso().LABEL[row.situacao]}
                                                        />
                                                    </SttText>
                                                )}
                                            </SttTableCell>
                                        </SttHidden>
                                        <SttTableCell align="center">
                                            {opcoes.value.length > 0 && (
                                                <PopupState variant="popover">
                                                    {(popupState) => (
                                                        <React.Fragment>
                                                            <IconButton aria-haspopup="true" {...bindTrigger(popupState)}>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <SttMenu {...bindMenu(popupState)}>
                                                                {opcoes.value.map((item, idx) => {
                                                                    if (item.showFunction(row)) {
                                                                        return (
                                                                            <SttMenuItem
                                                                                key={uuidv4()}
                                                                                onClick={() => {
                                                                                    popupState.close();
                                                                                    item.createOnClick(row);
                                                                                }}
                                                                            >
                                                                                <SttListItemIcon>
                                                                                    <item.icon />
                                                                                </SttListItemIcon>
                                                                                <SttListItemText primary={item.title} />
                                                                            </SttMenuItem>
                                                                        );
                                                                    }
                                                                })}
                                                            </SttMenu>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                            )}
                                        </SttTableCell>
                                    </SttTableRow>
                                ))
                            ))}
                    </SttTableBody>
                </SttTable>

                <Operacoes
                    tipoOperacao={tipoOperacao}
                    open={encaminharAutorizador}
                    id={processosSelecionados.value.map((item) => item.id)}
                    outrosDados={outrosDados}
                    voltarPesquisa={() => {
                        processosSelecionados.value = [];
                        pesquisarProcessos.value = true;
                    }}
                />
            </TableContainer>
            {processos.value.length > 0 && (
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={totalRegistros.value}
                    rowsPerPage={count.value}
                    page={page.value}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            )}
        </>
    );
};

export default PesquisaApac;
